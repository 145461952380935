import { useContext, useEffect, useRef, useState } from 'react';
import css from './style.module.css';
import { MarktingContext } from '../../Context/MarktingContext';
import Resolved from './Resolved';
import CommentReplyInput from './CommentReplyInput';

export default function DocumentComments({ id, comments, setComments, activeComment, loginToken }) {
    const commentRefs = useRef({});
    const [activeCommentId, setActiveCommentId] = useState(null);
    const { marktingData } = useContext(MarktingContext);

    useEffect(() => {
        if (marktingData.length !== 0) {
            let comments = [];
            const element = marktingData?.find((item) =>
                item?.documentId === id ||
                item?.version?.some(version => version.versionId === id)
            );

            if (element.documentId === id) {
                comments = element?.Comments ?? [];
            } else if (element?.version?.some(version => version.versionId === id)) {
                comments = element?.version?.find(version => version.versionId === id)?.Comments ?? [];
            }
            
            // Set the comments in the state
            setComments(comments);
        }
    }, [marktingData, id]);

    const formatDate = (isoString) => {
        const date = new Date(isoString);
        return date.toLocaleString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: '2-digit',
            hour12: true,
        });
    };

    const scrollToComment = (key) => {
        const elementOrElements = commentRefs.current[key];
        if (elementOrElements) {
            if (Array.isArray(elementOrElements)) {
                // Scroll each element into view.
                elementOrElements.forEach((el) => {
                    if (el) {
                        el.scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                            inline: "center",
                        });
                    }
                });
            } else {
                // If it’s a single element, scroll it into view.
                elementOrElements.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                    inline: "center",
                });
            }
            setActiveCommentId(key);
        } else {
            setActiveCommentId(null);
        }

    };


    useEffect(() => {
        if (activeComment) {
            // Use elementId if available, otherwise use id.
            const commentKey = activeComment.elementId || activeComment.id;
            scrollToComment(commentKey);
        }
    }, [activeComment]);

    return (
        <div className="vh-100 container px-3 py-4 overflow-auto">
            <div className="row">
                <h5 className={css.headerText}>Comments</h5>
                <h5 className={css.headerText}>feed.</h5>
                <div className="col-md-12">
                    {comments
                        ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                        ?.map((comment) => (
                            <div
                                ref={(el) => {
                                    commentRefs.current[comment.id] = el;
                                    if (comment.elementId) {
                                        commentRefs.current[comment.elementId] = el;
                                    }
                                }}
                                key={comment.id}
                                className={` ${css.commentBox} ${activeCommentId === comment.id || activeCommentId === comment.elementId
                                    ? css.activeCommentBox
                                    : ''
                                    }`}
                            >
                                <div className="d-flex justify-content-between mb-3">
                                    <div className="d-flex align-items-center">
                                        <i className="fa-regular fa-face-smile fa-xl me-2 text-center mt-1"></i>
                                        <p className="m-0" style={{ fontSize: "1.313rem" }}>{comment.created_by}</p>
                                    </div>
                                    <Resolved loginToken={loginToken} comentId={comment.id} resolvedStatus={comment.resolved} />
                                </div>
                                <p className="mb-3 overflow-auto">{comment.comment}</p>
                                <p className="text-muted mb-2" style={{ fontSize: "0.938rem" }}>{formatDate(comment.createdAt)}</p>

                                <CommentReplyInput loginToken={loginToken} comentId={comment.id} />
                                {/* Replies Mapping */}
                                {comment.commentsReplay
                                    ?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
                                    ?.map((commentReplay) => (
                                        <div key={commentReplay.id}>
                                            <hr />
                                            <div className="d-flex align-items-center">
                                                <i className="fa-regular fa-face-smile fa-xl me-2 text-center mt-1"></i>
                                                <p className="m-0" style={{ fontSize: "1.313rem" }}>{commentReplay.created_by}</p>
                                            </div>
                                            <p className=" mt-3 overflow-auto">{commentReplay.commentReplay}</p>
                                            <p className="text-muted mb-2" style={{ fontSize: "0.938rem" }}>{formatDate(commentReplay.createdAt)}</p>
                                        </div>
                                    ))}

                            </div>

                        ))}
                </div>
            </div>
        </div>

    );
}
