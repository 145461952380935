import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { vendorLoginContext } from '../../Context/vendorLogin';
import pirmLogo from '../../images/pirmLogo.png';
import css from './style.module.css';
import { Decryption } from '../../lib/Decryption';
import { otpSchema } from '../../lib/Yup Validation/VendorsSchemas';
import { DomainSwitchContext } from '../../Context/DomainSwitch.Context';
import LoadingOtp from '../LoadingPages/LoadingOtp';

export default function Otp() {
    const { logo } = useContext(DomainSwitchContext);
    const { otpCode, setOtpCodeNumber } = useContext(vendorLoginContext);
    const [data, setData] = useState({ email: '', password: '' });
    const [spinner, setSpinner] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);  // Add loading state

    useEffect(() => {
        if (logo === null) {
            setLoading(true); // Show LoadingOtp if logo is null
        } else {
            setLoading(false); // Hide LoadingOtp when logo is available
        }
    }, [logo]);

    if (loading) {
        return <LoadingOtp />;  // Show the LoadingOtp component while waiting for logo
    }

    // Get the data from input
    const getData = (e) => {
        const { name, value } = e.target;
        setData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const saveToStorage = (key, value, ttl) => {
        const expiry = Date.now() + ttl; // Time-to-live in milliseconds
        const data = { value, expiry };
        localStorage.setItem(key, JSON.stringify(data));
    };

    const login = async () => {
        setError('');
        setSpinner(true);

        try {
            // Validate the input using Yup (pass the entire data object)
            await otpSchema.validate(data, { abortEarly: false });

            const { email } = data;

            // Proceed with the login API call after validation
            const res = await otpCode(email);
            const id = res.data.userRecord.id;
            const otp = Decryption(id);

            saveToStorage('Check', id, 600000);
            setOtpCodeNumber(otp);

            if (res.status === 200) {
                navigate('/forgetpassword');
            }
        } catch (err) {

            // Display validation errors
            setError(err.errors[0] || 'An unexpected error occurred. Please try again.');
        } finally {
            setSpinner(false);
        }
    };

    return (
        <>

            <div className="border border-2 rounded documents">
                <header className="text-primary py-3 fw-bold px-5 mt-2 border-bottom border-2 rounded-top text-center">
                    <img src={logo} alt="Company logo" className={css.logo} />
                </header>
                <main className="py-3 text-center px-4">
                    <p className="text-center fw-bold mt-4 mb-2" style={{ fontWeight: 400, fontSize: '1.25rem' }}>
                        Compliance Portal
                    </p>

                    <label htmlFor="email" className="d-flex justify-start mb-2 ms-2">
                        Email
                    </label>
                    <input
                        type="email"
                        name="email"
                        id="email"
                        className="form-control me-3 mb-3 inputStyle"
                        onChange={getData}
                        placeholder="example@example.com"
                    />
                    <button
                        onClick={login}
                        className="btn text-white my-4 mx-3 px-5 py-2 rounded-1"
                        style={{ backgroundColor: '#2268DE' }}
                        disabled={spinner} // Disable button when spinner is active
                    >
                        Verify Your Email
                        {spinner && <i className="ms-3 fas fa-spinner fa-pulse"></i>}
                    </button>
                    {error && <div className="alert alert-danger">{error}</div>} {/* Error message display */}
                </main>
            </div>

        </>
    );
}