import React, { useContext, useState, useEffect, useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { MarktingContext } from "../../Context/MarktingContext";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import { SetupContext } from "../../Context/SetupContext";
import SelectOption from "./SelectOption";

export default function AddMarktingForm({ animationPlayed, marketingDocId = null, setDocInfo, loginToken }) {
    const version = `version/${marketingDocId}`;
    const { setMarktingData, marktingData } = useContext(MarktingContext);
    const { manageFields } = useContext(SetupContext);

    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const setFieldValueRef = useRef();
    const navigation = useNavigate();
    useEffect(() => {
        if (animationPlayed) {
            setFiles([]);
        }
    }, [animationPlayed]);
    const validationSchema = Yup.object().shape({
        ...(!marketingDocId && {
            documentName: Yup.string().required("Document Name is required.")
        }),
        file: Yup.mixed()
            .required("A file is required.")
            .test(
                "fileType",
                "Unsupported file format. Please upload an HTML file, an Image, a PDF, or a Word document.",
                (value) =>
                    value &&
                    [
                        "image/jpeg",
                        "image/webp",
                        "image/png",
                        "image/gif",
                        "application/pdf",
                        "application/msword",
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                        "text/html"
                    ].includes(value.type)
            ),
        fields: Yup.object().shape({
            documentType: Yup.string().required("Document type is required."),
            mediaChannel: Yup.string().required("Media channel is required."),
            targetGeography: Yup.string().required("Target geography is required."),
        }),
        description: Yup.string(),
    });

    const uploadFiles = async (values, resetForm) => {
        const { protocol, hostname, port } = window.location;
        let fullUrl = `${protocol}//${hostname}${port ? `:${port}` : ""}/`;
        setLoading(true);
        try {
            const file = files[0];
            const reader = new FileReader();

            // Handle errors during file reading
            reader.onerror = (err) => {
                console.error("FileReader error:", err);
                setLoading(false);
            };

            reader.onload = async (event) => {
                try {
                    const base64File = event.target.result.split(",")[1];
                    const payload = {
                        documentName: values.documentName,
                        documentType: values.fields.documentType,
                        mediaChannel: values.fields.mediaChannel,
                        targetGeography: values.fields.targetGeography,
                        description: values.description.trim(),
                        fileName: file.name,
                        mimeType: file.type,
                        fileData: base64File,
                        clientUrl: fullUrl
                    };

                    const { data } = await axios.post(
                        `https://us-central1-policy-portal-4ca94.cloudfunctions.net/credentialsFunc/${marketingDocId ? version : 'marketing'}`,
                        payload,
                        {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${loginToken}`,
                            },
                        }
                    );

                    const res = data?.data;
                    if (res?.marketingDocumentId) {
                        const deepClone = JSON.parse(JSON.stringify(marktingData));
                        const index = deepClone.findIndex((item) => item.documentId === res.marketingDocumentId);
                        if (index !== -1) {
                            // If version is undefined, initialize it as an array, otherwise push new version
                            deepClone[index].version = deepClone[index].version === undefined ? [res] : deepClone[index].version.concat(res);
                            deepClone[index].version = deepClone[index].version.sort((a, b) => b.versionNumber - a.versionNumber);
                            setDocInfo(deepClone[index]);
                            setMarktingData(deepClone);
                        }
                    } else {
                        setMarktingData((prevData) => [...prevData, data.data]);
                    }
                    setLoading(false);
                    setFiles([]);
                    resetForm();
                } catch (error) {
                    console.error("Error in onload callback:", error);
                    // You can also handle 401 here if needed
                    if (error.response && error?.response?.status === 401) {
                        localStorage.removeItem("Firebase Auth");
                        localStorage.removeItem("okta-token-storage");
                        localStorage.removeItem("okta-cache-storage");
                        navigation('/');
                        console.error("session expired");
                        return;
                    }
                    console.error("Error uploading files:", error.response || error.message);
                    setLoading(false);
                }
            };

            reader.readAsDataURL(file);
        } catch (error) {
            if (error.response && error?.response?.status === 401) {
                localStorage.removeItem("Firebase Auth");
                localStorage.removeItem("okta-token-storage");
                localStorage.removeItem("okta-cache-storage");
                navigation('/');
                console.error("session expired");
                return;
            };
            console.error("Error uploading files:", error.response || error.message);
            setLoading(false);
        }
    };


    const onDrop = (acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            setFiles(acceptedFiles);
            setFieldValueRef.current?.("file", acceptedFiles[0]);
        }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: [
            "image/jpeg",
            "image/webp",
            "image/png",
            "image/gif",
            "application/pdf",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "text/html"
        ],
    });

    return (
        <AnimatePresence>
            {animationPlayed && (
                <motion.div
                    initial={{ height: 0, opacity: 0, overflow: "hidden" }}
                    animate={{ height: "auto", opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{
                        height: { duration: 0.8, ease: "easeInOut" },
                        opacity: { duration: 0.5, ease: "easeInOut" },
                        delayChildren: 0.3,
                        staggerChildren: 0.1,
                    }}
                >
                    <Formik
                        initialValues={{
                            documentName: "",
                            file: null,
                            fields: {
                                documentType: '',
                                mediaChannel: '',
                                targetGeography: ''
                            },
                            description: ''
                        }}
                        validationSchema={validationSchema}
                        onSubmit={async (values, { setSubmitting, resetForm }) => {
                            setSubmitting(true);
                            try {
                                await uploadFiles(values, resetForm);
                            } catch (error) {
                                console.error("Error during file upload:", error);
                            } finally {
                                setSubmitting(false);
                            }
                        }}
                    >
                        {({ isSubmitting, errors, touched, setFieldValue }) => {
                            setFieldValueRef.current = setFieldValue;
                            return (
                                <Form>
                                    {!marketingDocId && <div className="m-2">
                                        <label htmlFor="documentName ">Document Name <span className="text-danger">*</span></label>
                                        <Field
                                            className="form-control"
                                            type="text"
                                            name="documentName"
                                            placeholder="Enter your Document Name"
                                            id="documentName"
                                        />
                                        {errors.documentName && touched.documentName && (
                                            <div style={{ color: "red" }}>{errors.documentName}</div>
                                        )}
                                    </div>}
                                    {/* selected fields */}
                                    {!marketingDocId && (
                                        <div className="m-2">
                                            <SelectOption
                                                label={
                                                    <>
                                                        Document Type <span className="text-danger">*</span>
                                                    </>
                                                }
                                                name="fields.documentType"
                                                placeholder="Select Document Type"
                                                options={
                                                    manageFields?.types
                                                        ? manageFields.types.map((item) => ({
                                                            value: item.type,
                                                            label: item.type,
                                                        }))
                                                        : []
                                                }
                                            />

                                            <SelectOption
                                                label={
                                                    <>
                                                        Media Channel <span className="text-danger">*</span>
                                                    </>
                                                }
                                                name="fields.mediaChannel"
                                                placeholder="Select Media Channel"
                                                options={
                                                    manageFields?.channels
                                                        ? manageFields.channels.map((item) => ({
                                                            value: item.channel,
                                                            label: item.channel,
                                                        }))
                                                        : []
                                                }
                                            />

                                            <SelectOption
                                                label={
                                                    <>
                                                        Target Geography <span className="text-danger">*</span>
                                                    </>
                                                }
                                                name="fields.targetGeography"
                                                placeholder="Select Target Geography"
                                                options={
                                                    manageFields?.geographies
                                                        ? manageFields.geographies.map((item) => ({
                                                            value: item.geography,
                                                            label: item.geography,
                                                        }))
                                                        : []
                                                }
                                            />
                                        </div>
                                    )}


                                    {/* description textarea */}
                                    {!marketingDocId && <div className="m-2">
                                        <label htmlFor="description">Description</label>
                                        <Field
                                            className="form-control"
                                            as="textarea"
                                            name="description"
                                            placeholder="Enter your description"
                                        />
                                        {errors.description && touched.description && (
                                            <div style={{ color: "red" }}>{errors.description}</div>
                                        )}
                                    </div>}

                                    {/* file upload */}
                                    <label htmlFor="file" className="m-2 mb-0">Upload File <span className="text-danger">*</span></label>
                                    <p className="m-0 text-muted fw-light ms-2">Allowed file types: HTML, Images, PDFs, and Word documents</p>
                                    <div
                                        {...getRootProps()}
                                        className={`dropzone ${isDragActive ? "active" : ""} m-2`}
                                        style={{
                                            border: "2px dashed #ccc",
                                            padding: "20px",
                                            textAlign: "center",
                                            cursor: "pointer",
                                            backgroundColor: "#fff",
                                        }}
                                    >
                                        <input
                                            {...getInputProps()}
                                            onChange={(event) => {
                                                const file = event.target.files[0];
                                                setFiles([file]);
                                                setFieldValue("file", file);
                                            }}
                                        />
                                        {isDragActive ? (
                                            <p>Drop the files here...</p>
                                        ) : (
                                            <p>Drag and drop files here, or click to select files.</p>
                                        )}
                                    </div>

                                    {errors.file && touched.file && (
                                        <div style={{ color: "red" }} className="ms-2">{errors.file}</div>
                                    )}

                                    {files.length > 0 && (
                                        <ul>
                                            {files?.map((file, index) => (
                                                <li key={index}>
                                                    {file.name} ({Math.round(file.size / 1024)} KB)
                                                </li>
                                            ))}
                                        </ul>
                                    )}

                                    <button
                                        type="submit"
                                        disabled={isSubmitting || loading}
                                        className="btn btn-success m-2"
                                    >
                                        {loading ? "Uploading..." : "Upload"}
                                    </button>
                                </Form>
                            );
                        }}
                    </Formik>
                </motion.div>
            )}
        </AnimatePresence>
    );
}