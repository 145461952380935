import React, { useContext, useEffect, useState } from 'react';
import { SetupContext } from '../../Context/SetupContext';
import css from './style.module.css';

export default function ManageFields({ objKey, editIndex, setEditIndex }) {
    const { manageFields, updateField, deleteField, addField } = useContext(SetupContext);
    const [field, setField] = useState([]);
    const [editValue, setEditValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [disabledFields, setDisabledFields] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
    const [newFieldValue, setNewFieldValue] = useState('');

    useEffect(() => {
        // Update local field state when manageFields changes for this key.
        if (manageFields[objKey]?.length > 0) {
            setField(manageFields[objKey]);
        } else {
            setField([]);
        }
    }, [manageFields, objKey]);

    // Helper function to get the display value based on objKey
    const getDisplayValue = (item) => {
        switch (objKey) {
            case 'types':
                return item.type;
            case 'channels':
                return item.channel;
            case 'geographies':
                return item.geography;
            default:
                return '';
        }
    };

    const handleKeyDown = async (e, id) => {
        if (e.key === 'Enter') {
            setLoading(true);
            await updateField(objKey, id, editValue);
            setEditIndex(null);
            setLoading(false);
        } else if (e.key === 'Escape') {
            setEditIndex(null);
        }
    };

    const updateFunc = async (objKey, id, updateValue) => {
        if (updateValue.trim() === '') return;
        setLoading(true);
        await updateField(objKey, id, updateValue);
        setEditIndex(null);
        setLoading(false);
    };

    const deleteFunc = async (objKey, id) => {
        await deleteField(objKey, id);
        // Optionally, you can remove the disabled flag if needed or let the list update
        setDisabledFields(prev => {
            const newState = { ...prev };
            delete newState[id];
            return newState;
        });
    };

    const addNewField = async () => {
        if (newFieldValue.trim() === '') return;
        setLoading(true);
        await addField(objKey, newFieldValue);
        setNewFieldValue('');
        setLoading(false);
    };

    // Filter fields based on searchTerm
    const filteredFields = field.filter(item =>
        getDisplayValue(item)?.toLowerCase()?.includes(searchTerm.toLowerCase())
    );

    const renderFieldItems = () => {
        return (
            <>
                <div className="col-md-4">
                    <div className="search-container position-relative">
                        <div className="input-group search-bar">
                            <input
                                type="text"
                                className="form-control inputColor py-2"
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                            <span className="input-group-text">
                                <i className="fa-solid fa-magnifying-glass"></i>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="col-md-8 d-flex justify-content-end align-items-center">
                    <div className="input-group w-auto">
                        <input
                            id='newField'
                            type="text"
                            className={`form-control shadow-none ${css.inputBorderColor} placeholder:m-0`}
                            placeholder="New Option"
                            value={newFieldValue}
                            onChange={(e) => setNewFieldValue(e.target.value)}
                        />
                        <button className="btn btnCustom" onClick={addNewField}>
                            Add Option {loading && <i className="fa-solid fa-circle-notch fa-spin ms-2"></i>}
                        </button>
                    </div>
                </div>
                {field.length === 0 ? (
                    <div className="col-md-12 ms-2 mt-3">
                        <p className="text-muted">No {objKey} found</p>
                    </div>
                ) : (
                    filteredFields.map((item, index) => (
                        <div
                            key={index}
                            className={`col-md-3 ${css.field} shadow-sm ${disabledFields[item.Id] ? css.disabledDiv : ""} ms-4 mt-3`}
                        >
                            <div className="d-flex justify-content-between align-items-center">
                                {editIndex === index ? (
                                    <input
                                        type="text"
                                        value={editValue}
                                        onChange={(e) => setEditValue(e.target.value)}
                                        onKeyDown={(e) => handleKeyDown(e, item.Id)}
                                        className="m-0 ms-2"
                                        autoFocus
                                    />
                                ) : (
                                    <p className="m-0 ms-2">{getDisplayValue(item)}</p>
                                )}
                                <div>
                                    <i
                                        className={`fa-solid fa-pen ${css.pen} me-3`}
                                        onClick={() => {
                                            setEditIndex(index);
                                            setEditValue(getDisplayValue(item));
                                        }}
                                    ></i>
                                    {disabledFields[item.Id] ? (
                                        <i className="fa-solid fa-circle-notch fa-spin ms-2"></i>
                                    ) : (
                                        <i
                                            className={`fa-solid fa-trash ${css.trash} me-2`}
                                            onClick={() => {
                                                // Disable only this specific field
                                                setDisabledFields(prev => ({
                                                    ...prev,
                                                    [item.Id]: true
                                                }));
                                                deleteFunc(objKey, item.Id);
                                            }}
                                        ></i>
                                    )}
                                </div>
                            </div>
                            {editIndex === index && (
                                <div className="row mt-2">
                                    <div className="col">
                                        <button
                                            className="btn btn-success w-100"
                                            onClick={async () => {
                                                await updateFunc(objKey, item.Id, editValue);
                                            }}
                                        >
                                            Update
                                            {loading && <i className="fa-solid fa-circle-notch fa-spin ms-2"></i>}
                                        </button>
                                    </div>
                                    <div className="col">
                                        <button className="btn btn-danger w-100" onClick={() => setEditIndex(null)}>
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    ))
                )}
            </>
        );
    };

    return (
        <div className="container">
            <div className="row">
                {renderFieldItems()}
            </div>
        </div>
    );
}
