import React, { useEffect } from 'react';
import css from './style.module.css';

export default function SetupTabPanel({ objKey, setKey, tabName, activeTab, setActiveTab, setEditIndex }) {
    return (
        <li
            className={`tabItem ${activeTab === tabName ? css.active : ''}`}
            onClick={() => { setActiveTab(tabName); setKey(objKey); setEditIndex(null) }}
        >
            <span >{tabName}</span>
        </li>
    );
}
