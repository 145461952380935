import React from 'react'
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import css from '../Marketing Compliance/style.module.css';
export default function LoadingMarketingDetails() {
    // height={50} width={150}
    return (
        <div className="container-fluid mt-3 ms-3">
            <div className={`container-fluid ${css.documetContainer} py-1`}>
                <div className="row">
                    <div className="col-md-12 d-flex justify-content-between align-items-center py-1">
                        <div className='d-flex align-items-center'>
                            <Skeleton className='me-2' height={20} width={80} baseColor="#EBE6F0" />
                            <Skeleton className='me-2' height={20} width={80} baseColor="#EBE6F0" />
                            <Skeleton height={20} width={35} baseColor="#EBE6F0" />
                        </div>
                        <div className='d-flex align-items-center'>
                            <Skeleton baseColor="#EBE6F0" className='me-2' height={20} width={80} />
                            <Skeleton baseColor="#EBE6F0" height={20} width={80} />
                        </div>
                    </div>
                </div>
            </div >
            <div className="row d-flex">
                {/* porgress indicator  */}
                <div className={`${css.indicatorContainer} my-3`}>
                    <div className={css.row}>
                        {[...Array(4)].map((_, index) => (
                            <div key={index} className={css.colMd4}>
                                <div
                                    className={`${css.indicator} d-flex align-items-center justify-content-center`}
                                    style={{ backgroundColor: "#EBE6F0", pointerEvents: "none" }}
                                >
                                    <p className="text-center text-dark m-0 fw-semibold">
                                        <Skeleton width={200} height={20} baseColor="#EBE6F0" />
                                    </p>
                                    <div className={`${css.arrowRightStart}`} ></div>
                                    <div
                                        className={`${css.arrowRight}`}
                                        style={{ borderLeft: "25px solid #EBE6F0" }}
                                    ></div>
                                </div>
                            </div>

                        ))}
                    </div>
                </div>
                {/*  */}
                <div className="col-md-9 vh-100 mt-2">

                    <Skeleton baseColor="#EBE6F0" height="95%" width="100%" />
                </div>
                <div className="col-md-3 mt-2">
                    <div className="vh-100 container px-3 py-4 overflow-auto">
                        <div className="row">
                            <h5 className={css.headerText} style={{ marginBottom: "7px" }}><Skeleton height={22} width={200} baseColor="#EBE6F0" /></h5>
                            <h5 className={css.headerText}><Skeleton height={22} width={100} baseColor="#EBE6F0" /> </h5>
                            <div className="col-md-12">
                                {Array(3).fill(0).map((_, index) => (
                                    <div
                                        key={index}
                                        className={` ${css.commentBox} `}
                                        style={{ borderColor: "#EBE6F0" }}
                                    >

                                        <p className='m-0' style={{ fontSize: "1.313rem" }}> <Skeleton height={20} width="100%" baseColor="#EBE6F0" /> </p>

                                        <p className='mt-2 mb-4 overflow-auto'> <Skeleton height={20} width="100%" baseColor="#EBE6F0" /> </p>
                                        <p className='text-muted' style={{ fontSize: "0.938rem" }}> <Skeleton height={20} width="100%" baseColor="#EBE6F0" /> </p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
