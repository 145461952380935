import React, { useContext, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios"; // Import Axios
import sendSvg from "../../images/Send.svg";
import { MarktingContext } from "../../Context/MarktingContext";
import { useOktaConfig } from "../../Context/CredentialsContext";
import { useNavigate } from "react-router-dom";

export default function CommentReplyInput({ comentId, loginToken }) {
    const [reply, setReply] = useState(false);
    const { setMarktingData, marktingData, userName } = useContext(MarktingContext);


const navigation = useNavigate();
    // Yup validation schema
    const validationSchema = Yup.object().shape({
        comment: Yup.string()
            .trim()
            .required("Comment cannot be empty"),
    });

    // API Call Function
    const submitComment = async (comment) => {
        try {

            const payload = {
                commentReplay: comment,
            };

            const res = await axios.post(`https://us-central1-policy-portal-4ca94.cloudfunctions.net/credentialsFunc/comment/replay/${comentId}`, payload, {

                headers: {
                    Authorization: `Bearer ${loginToken}`,
                },

            });


            if (res.status === 200) {
                const deepCopy = [...marktingData]; // Create a deep copy

                const element = deepCopy.find((item) =>
                    item?.Comments.some(comment => comment.id === comentId) ||
                    item?.version?.some(version => version?.Comments.some(comment => comment.id === comentId))
                );

                if (element) {
                    // Update the comment in the main Comments array
                    const commentIndex = element.Comments.findIndex(comment => comment.id === comentId);

                    if (commentIndex !== -1) {

                        element.Comments[commentIndex].commentsReplay ? element.Comments[commentIndex].commentsReplay.push(res.data.data) :
                            element.Comments[commentIndex].commentsReplay = [res.data.data];
                    } else {
                        // If not found in main Comments, search in version Comments
                        element.version.forEach(version => {
                            const versionCommentIndex = version.Comments.findIndex(comment => comment.id === comentId);
                            if (versionCommentIndex !== -1) {
                                version.Comments[versionCommentIndex].commentsReplay ? version.Comments[versionCommentIndex].commentsReplay.push(res.data.data) :
                                    version.Comments[versionCommentIndex].commentsReplay = [res.data.data];
                            }
                        });
                    }
                    setMarktingData(deepCopy);
                }
            }

            // return response.data;
        } catch (error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem("Firebase Auth");
                localStorage.removeItem("okta-token-storage");
                localStorage.removeItem("okta-cache-storage");
                navigation('/');
                console.error("seassion expired");
                return;
            };
            console.error("Error submitting comment:", error.response?.data || error.message);
            throw error;
        }
    };

    return (
        <>
            <p className="text-primary text-decoration-underline m-0 mb-2 holdCursor"
                onClick={() => setReply(!reply)}>
                Reply
            </p>

            {reply && (
                <Formik
                    initialValues={{ comment: "" }}
                    validationSchema={validationSchema}
                    onSubmit={async (values, { resetForm }) => {
                        try {
                            await submitComment(values.comment);
                            resetForm(); // Clear input after successful submission
                        } catch (error) {
                            alert("Failed to submit comment. Try again.");
                        }
                    }}
                >
                    {({ handleSubmit }) => (
                        <Form onSubmit={handleSubmit}>
                            <div className="d-flex align-items-center justify-content-between">
                                <div style={{ width: "90%" }}>
                                    <Field
                                        as="textarea"
                                        name="comment"
                                        className="my-2 me-2 form-control bg-transparent"
                                        placeholder="Add a Comment here"
                                        style={{
                                            width: "100%",
                                            resize: "none",
                                            overflowWrap: "break-word",
                                            wordBreak: "break-word",
                                            minHeight: "40px",
                                            height: "40px",
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter" && !e.shiftKey) {
                                                e.preventDefault();
                                                handleSubmit();
                                            }
                                        }}
                                    />

                                </div>

                                <div
                                    onClick={handleSubmit} // Call your submit function here
                                    style={{
                                        width: "35px",
                                        height: "35px",
                                        borderRadius: "50%",
                                        border: "none",
                                        background: "transparent",
                                        cursor: "pointer", // Makes it behave more like a button
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}
                                    className="shadow-sm ms-2 holdCursor"
                                >
                                    <img src={sendSvg} style={{ width: "90%" }} alt="Send" />
                                </div>
                            </div>
                            <ErrorMessage name="comment" component="div" className="text-danger small" />
                        </Form>
                    )}
                </Formik>
            )}
        </>
    );
}
