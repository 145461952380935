import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import { useOktaConfig } from "./CredentialsContext";
import { useNavigate } from "react-router-dom";

export const SetupContext = createContext([]);



export default function SetupProvider({ children }) {
    const { loginToken } = useOktaConfig();
    const [manageFields, setManageFields] = useState({});

    const navigation = useNavigate();

    const getManageFields = async () => {
        try {
            const { data, status } = await axios.get("https://us-central1-policy-portal-4ca94.cloudfunctions.net/credentialsFunc/fields",
                {
                    headers: {
                        Authorization: `Bearer ${loginToken}`,
                    },
                }
            );
            if (status === 200) {
                setManageFields(data)
            }
        }
        catch (error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem("Firebase Auth");
                localStorage.removeItem("okta-token-storage");
                localStorage.removeItem("okta-cache-storage");
                navigation('/');
                console.error("seassion expired");
                return;
            };
            console.log("getManageFields err => ", error);
        }
    }
    useEffect(() => {
        getManageFields()
    }, [loginToken]);

    const addField = async (objKey, field) => {

        try {
            const res = await axios.post(
                `https://us-central1-policy-portal-4ca94.cloudfunctions.net/credentialsFunc/${objKey}`,
                { field: field.trim() },
                {
                    headers: {
                        Authorization: `Bearer ${loginToken}`,
                    },
                }
            );
            if (res.status === 200) {
                // Check the API response before updating state
                const newField = res?.data[objKey];
                if (!newField) {
                    console.error("API did not return a valid field for", objKey);
                    return;
                }
                // Create a shallow copy of the object and deep copy the array for objKey
                const deepCopy = {
                    ...manageFields,
                    [objKey]: [...manageFields[objKey]]
                };

                deepCopy[objKey].push(newField);
                setManageFields(deepCopy);
                return deepCopy[objKey]; // Return the updated array
            }
        } catch (error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem("Firebase Auth");
                localStorage.removeItem("okta-token-storage");
                localStorage.removeItem("okta-cache-storage");
                navigation('/');
                console.error("seassion expired");
                return;
            };
            console.error('Error adding field:', error);
            // Optionally, handle error state here
        }
    };

    const updateField = async (objKey, id, updatedFields) => {
        try {
            const res = await axios.put(
                `https://us-central1-policy-portal-4ca94.cloudfunctions.net/credentialsFunc/${objKey}/${id}`,
                { field: updatedFields.trim() },
                {
                    headers: {
                        Authorization: `Bearer ${loginToken}`,
                    },
                }
            );
            if (res.status === 200) {
                // Create a shallow copy of the object
                const deepCopy = { ...manageFields };
            

                // Find the index of the item to update
                const index = deepCopy[objKey].findIndex((item) => item.Id === id);
     

                if (index !== -1) {
                    // Update the item. Adjust res.data.type if needed.
                    deepCopy[objKey][index] = res.data[objKey][0];
                   

                    setManageFields(deepCopy);
                }
                return manageFields[objKey];
            }
        } catch (error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem("Firebase Auth");
                localStorage.removeItem("okta-token-storage");
                localStorage.removeItem("okta-cache-storage");
                navigation('/');
                console.error("seassion expired");
                return;
            };
            console.error('Error updating field:', error);
        }
    };

    const deleteField = async (objKey, id) => {
        try {
            const res = await axios.delete(
                `https://us-central1-policy-portal-4ca94.cloudfunctions.net/credentialsFunc/${objKey}/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${loginToken}`,
                    },
                }
            );
            if (res.status === 200) {
                // Create a shallow copy of the object
                const deepCopy = { ...manageFields };

                // Find the index of the item to update
                const index = deepCopy[objKey].findIndex((item) => item.Id === id);

                if (index !== -1) {
                    // Update the item. Adjust res.data.type if needed.
                    deepCopy[objKey].splice(index, 1);
                    setManageFields(deepCopy);
                }
            }
        } catch (error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem("Firebase Auth");
                localStorage.removeItem("okta-token-storage");
                localStorage.removeItem("okta-cache-storage");
                navigation('/');
                console.error("seassion expired");
                return;
            };
            console.error('Error deleting field:', error);
        }
    };


    return (
        <SetupContext.Provider value={{ manageFields, setManageFields, addField, updateField, deleteField }}>
            {children}
        </SetupContext.Provider >
    )
}