import React, { useState } from 'react'
import css from "./style.module.css"
import SetupTabPanel from './SetupTabPanel';
import ManageFields from './ManageFields';
export default function Setup() {
  const [activeTab, setActiveTab] = useState("Document Type");
  const [key, setKey] = useState("types");
  const [editIndex, setEditIndex] = useState(null);


  return (
    <div className="container-fluid mt-3 ms-3">
      <div className="row align-items-center">
        <div className="col-md-12">
          {/* header */}
          <div className="d-flex align-items-end mb-2 justify-content-between">
            <h4 className="title ">Setup Dashboard</h4>
          </div>
          {/* body */}
          <p className='m-0' style={{ fontWeight: "200", fontSize: "1.7rem" }}>Manage Fields</p>
          <ul className="tabsContainer ">
            <SetupTabPanel tabName={"Document Type"} objKey={"types"} setKey={setKey} activeTab={activeTab} setActiveTab={setActiveTab} setEditIndex={setEditIndex} />
            <SetupTabPanel tabName={"Media Channel"} objKey={"channels"} setKey={setKey} activeTab={activeTab} setActiveTab={setActiveTab} setEditIndex={setEditIndex} />
            <SetupTabPanel tabName={"Target Geography"} objKey={"geographies"} setKey={setKey} activeTab={activeTab} setActiveTab={setActiveTab} setEditIndex={setEditIndex} />
          </ul>
          <ManageFields objKey={key} setEditIndex={setEditIndex} editIndex={editIndex} />
        </div>
      </div>
    </div>
  )
}
