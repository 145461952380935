import React, { useContext, useEffect, useState } from 'react';
import css from './style.module.css';
import { AnimatePresence, motion } from 'framer-motion';
import axios from 'axios';
import { MarktingContext } from '../../Context/MarktingContext';
import { useNavigate } from 'react-router-dom';


export default function Header({
    documentStatus, setDocumentStatus, id, animationPlayed,
    setAnimationPlayed, setCurrentVersion, filestatus, setDocInfo,
    setFileStatus, setComments, setId, setConvertedHtml, loginToken
}) {

    const { setMarktingData, marktingData, userName, fetchPublicUrl } = useContext(MarktingContext);
    const [listToggle, setListToggle] = useState(false);
    const [docData, setDocData] = useState([]);
    const [versionNumber, setVersionNumber] = useState(0);
    const statusValues = ['Awaiting Review', 'Comments Provided', 'Adjustments Made', 'Active Version'];
    const navigation = useNavigate();
    useEffect(() => {
        if (id) {

            const docInfo = marktingData.find((item) =>
                item?.documentId === id ||
                item?.version?.some(version => version.versionId === id)
            );
            if (docInfo?.documentId === id) {
                setVersionNumber(docInfo?.firstVersion);
            } else {
                const latestVersion = docInfo?.version.find((version) => version.versionId === id);
                setVersionNumber(latestVersion?.versionNumber);
            }


            setDocData(docInfo || []);
        }

    }, [id, marktingData]);

    const AutoSetRanking = () => {
        const index = statusValues.findIndex((item) => item === documentStatus);
        const nextIndex = index + 1;
        return nextIndex < statusValues.length ? statusValues[nextIndex] : statusValues[0];
    };

    const UpdateStatus = async (id) => {
        if (!id) return;

        try {
            const payload = { status: filestatus || AutoSetRanking()};

            const res = await axios.put(`https://us-central1-policy-portal-4ca94.cloudfunctions.net/credentialsFunc/marketing/${id}`, payload, {
                headers: {
                    Authorization: `Bearer ${loginToken}`,
                },
            });

            if (res.status === 200) {
                setDocumentStatus(payload.status);
                const deepCopy = [...marktingData];
                const index = deepCopy.findIndex((item) => item.documentId === id);

                if (index !== -1) {
                    deepCopy[index] = res.data.data;
                    setMarktingData(deepCopy);
                    setDocInfo(res.data.data);
                    setFileStatus(null);
                }
            }
        } catch (error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem("Firebase Auth");
                localStorage.removeItem("okta-token-storage");
                localStorage.removeItem("okta-cache-storage");
                navigation('/');
                console.error("seassion expired");
                return;
            };
            console.error("Error updating status:", error);
        }
    };

    if (docData.length === 0) {
        return <p>Loading...</p>;
    }

    const handleVersionClick = async (version) => {
        const { versionId, documentUrl, convertedBefore } = version;

        const documentUrlTest = typeof documentUrl === "string" ? documentUrl : "";

        const isHtml = [".html", ".htm", "HTML"].some(ext => documentUrlTest.includes(ext));

        setId(versionId);
        if (!isHtml) {
            const publicUrl = await fetchPublicUrl(versionId);
            setCurrentVersion(publicUrl);

        } else {
            setCurrentVersion(documentUrl);
        }
        setConvertedHtml(convertedBefore);
        setVersionNumber(version.versionNumber);

        const comments = marktingData
            .flatMap((item) => [
                ...(item.documentId === versionId ? item.Comments ?? [] : []),
                ...(item.version?.find((version) => version.versionId === versionId)?.Comments ?? []),
            ]);

        setComments(comments);
    };

    return (
        <div className={`container-fluid ${css.documetContainer} py-1`}>
            <div className="row">
                <div className="col-md-12 d-flex justify-content-between align-items-center">
                    <div className='d-flex align-items-center'>
                        <p className='text-center my-auto fw-bold fs-5'>{docData?.documentName} - V{versionNumber ?? versionNumber}</p>
                        {docData?.version?.length > 0 && <div className='position-relative z-3'>
                            <motion.div
                                initial={{ rotateX: 0 }}
                                animate={{ rotateX: listToggle ? 180 : 0 }}
                                transition={{ duration: 0.9, ease: "easeInOut" }}
                            >
                                <i className="ms-2 fa-regular fa-square-caret-down fa-xl holdCursor" onClick={() => setListToggle(!listToggle)}></i>
                            </motion.div>

                            <AnimatePresence>
                                {listToggle && (
                                    <motion.div
                                        initial={{ height: 0, opacity: 0, overflow: "hidden" }}
                                        animate={{ height: "auto", opacity: 1 }}
                                        exit={{ height: 0, opacity: 0 }}
                                        transition={{
                                            height: { duration: 0.8, ease: "easeInOut" },
                                            opacity: { duration: 0.5, ease: "easeInOut" },
                                            delayChildren: 0.3,
                                            staggerChildren: 0.1,
                                        }}
                                        className={`${css.versionList} d-flex justify-content-center align-items-center flex-column`}
                                    >
                                        <>
                                            {docData?.version?.sort((a, b) => b.versionNumber - a.versionNumber).map((version) => (
                                                <p key={version.versionId} className={`m-0 ${css.versionListVersion} w-100 text-center`} onClick={() => handleVersionClick(version)} >Version {version.versionNumber}</p>
                                            ))}
                                            <p className={`m-0 ${css.versionListVersion}  w-100 text-center`} onClick={() => handleVersionClick({ versionId: docData.documentId, documentUrl: docData.documentUrl, versionNumber: 1, convertedBefore: docData.convertedBefore })}  >Version 1</p>
                                        </>
                                    </motion.div>
                                )}
                            </AnimatePresence>

                        </div>}
                    </div>
                    <div>
                        <button className={`btn ${css.statusButton} `} onClick={() => UpdateStatus(docData?.documentId)}>Mark as Current Status</button>
                        <button
                            className={`btn ${!animationPlayed ? "btn-primary" : "btn-danger "} text-white`}
                            onClick={() => setAnimationPlayed(!animationPlayed)}
                            style={{ width: "140px" }}
                        >
                            <AnimatePresence mode="wait">
                                <motion.span
                                    key={animationPlayed ? "cancel" : "add"}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={{ duration: 0.3, ease: "easeInOut" }}
                                >
                                    {animationPlayed ? "Cancel" : "New Version"}
                                </motion.span>
                            </AnimatePresence>
                        </button>

                    </div>
                </div>
            </div>
        </div >
    );
}
