import { useState, useCallback, useContext, useEffect } from "react";
import { motion } from "framer-motion";
import axios from "axios";
import { MarktingContext } from "../../Context/MarktingContext";
import { useNavigate } from "react-router-dom";

export default function Resolved({ comentId, resolvedStatus ,loginToken}) {
    const [isChecked, setIsChecked] = useState(resolvedStatus && resolvedStatus);
    const { marktingData, setMarktingData } = useContext(MarktingContext);

const navigation = useNavigate();
    const UpdateResolved = useCallback(async () => {
        try {
            const res = await axios.put(
                `https://us-central1-policy-portal-4ca94.cloudfunctions.net/credentialsFunc/comment/${comentId}`,
                { resolved: !isChecked },
                {
                    headers: {
                        Authorization: `Bearer ${loginToken}`,
                      },
                }

            );

            if (res.status === 200 && res.data.resolved !== isChecked) {
                setIsChecked(res.data.resolved);

                const deepCopy = JSON.parse(JSON.stringify(marktingData));

                const element = deepCopy.find((item) =>
                    item?.Comments.some(comment => comment.id === comentId) ||
                    item?.version?.some(version => version?.Comments.some(comment => comment.id === comentId))
                );

                if (element) {
                    // Update the comment in the main Comments array
                    const commentIndex = element.Comments.findIndex(comment => comment.id === comentId);
                    if (commentIndex !== -1) {
                        element.Comments[commentIndex].resolved = res.data.resolved;
                    } else {
                        // If not found in main Comments, search in version Comments
                        element.version.forEach(version => {
                            const versionCommentIndex = version.Comments.findIndex(comment => comment.id === comentId);
                            if (versionCommentIndex !== -1) {
                                version.Comments[versionCommentIndex].resolved = res.data.resolved;
                            }
                        });
                    }
                    setMarktingData(deepCopy);
                }
            }


        } catch (error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem("Firebase Auth");
                localStorage.removeItem("okta-token-storage");
                localStorage.removeItem("okta-cache-storage");
                navigation('/');
                console.error("seassion expired");
                return;
            };
            console.error("Failed to update resolved state:", error);
        }
    }, [comentId, isChecked, marktingData]);

    return (
        <div className="d-flex justify-content-center align-items-center">
            <motion.div
                className="position-relative d-flex justify-content-center align-items-center rounded-circle bg-transparent shadow-sm"
                style={{ width: "30px", height: "30px", cursor: "pointer", userSelect: "none" }}
                whileTap={{ scale: 0.9 }}
                onClick={UpdateResolved}
            >
                {isChecked ? (
                    <motion.span
                        key="check"
                        initial={{ scale: 0, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        exit={{ scale: 0, opacity: 0 }}
                        transition={{ type: "spring", stiffness: 300 }}
                        className="text-success fs-5"
                    >
                        ✔
                    </motion.span>
                ) : (
                    <motion.span
                        key="cross"
                        initial={{ y: -10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: 10, opacity: 0 }}
                        transition={{ duration: 0.3 }}
                        className="text-danger fs-6"
                    >
                        ❌
                    </motion.span>
                )}
            </motion.div>
        </div>
    );
}
