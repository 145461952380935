import React, { useContext, useState } from 'react';
import css from './style.module.css';
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from '../../lib/firebase'; // Ensure this path is correct
import { Link, useNavigate } from "react-router-dom";
import { VendorContext } from '../../Context/vendorContext';
import { DomainSwitchContext } from '../../Context/DomainSwitch.Context';
import VendorLoginLoading from '../LoadingPages/VendorLoginLoading';

export default function VendorLogin() {
    const { logo } = useContext(DomainSwitchContext);
    const { setEmail } = useContext(VendorContext);
    const [data, setData] = useState({ email: '', password: '' });
    const [spinner, setSpinner] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    // get the data from input
    const getData = (e) => {
        const { name, value } = e.target;
        setData((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const login = async () => {
        setError('');
        setSpinner(true);
        try {
            const { email, password } = data;
            const res = await signInWithEmailAndPassword(auth, email, password);
            localStorage.setItem("Firebase Auth", JSON.stringify(res.user.accessToken));
            const user = res.user;
            setEmail(user.email);
             localStorage.setItem("Vendor User", user.uid);
            if (res?.user) {
                navigate('/vendors');
            }
        } catch (error) {
            console.error('Error logging in:', error);
            setError(error.message);
        } finally {
            setSpinner(false);
        }
    };


    return (
        (logo ?
            <div className="border border-2 rounded documents">

                <header className="text-primary py-3 fw-bold px-5 mt-2 border-bottom border-2 rounded-top text-center">

                    <img src={logo} alt="Company logo" className={css.logo} />
                </header>
                <main className="py-3 text-center px-4">
                    <p className="text-center fw-bold mt-4 mb-2" style={{ fontWeight: 400, fontSize: "1.25rem" }}>
                        Compliance Portal
                    </p>

                    <label htmlFor="email" className='d-flex justify-start mb-2 ms-2'>Email</label>
                    <input
                        type="email"
                        name='email'
                        id='email'
                        className='form-control me-3 mb-3 inputStyle'
                        onChange={getData}
                        placeholder='example@example.com'
                    />
                    <label htmlFor="password" className='d-flex justify-start mb-2 ms-2'>Password</label>
                    <input
                        type="password"
                        id='password'
                        name='password'
                        className='form-control inputStyle'
                        onChange={getData}
                        placeholder='password'
                    />

                    <Link
                        to={`/otp`}
                        className="d-block mt-2 "
                        style={{
                            textDecorationColor: "#2268DE",
                            color: "#2268DE !important",
                        }}
                    >
                        forgot password
                    </Link>

                    <button
                        onClick={login}
                        className="btn text-white my-4 mx-3 px-5 py-2 rounded-1"
                        style={{ backgroundColor: "#2268DE" }}
                        disabled={spinner} // Disable button when spinner is active
                    >
                        Login
                        {spinner && <i className="ms-3 fas fa-spinner fa-pulse"></i>}
                    </button>
                    {error && <div className="alert alert-danger">{error}</div>} {/* Error message display */}
                </main>
            </div>
            : <VendorLoginLoading />)

    );
}
