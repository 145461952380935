import React, { useEffect, useState, memo } from 'react';
import { Link } from 'react-router-dom';
import SkeletonVendorPage from '../LoadingPages/SkeletonVendorPage';
import AgeDisplay from './AgeDisplay';


export default function MarketingComplianceTable({ data, status, tableTitel }) {
  const location = "Marketing Compliance";
  const [marktingData, setMarktingData] = useState(null);

  useEffect(() => {
    if (data) {
      setMarktingData(data);
    }
  }, [data]);

  if (!marktingData) {
    return <SkeletonVendorPage location={location} data={{ tableTitel: tableTitel }} />;
  }

  const filteredData = marktingData.filter(item => item.status === status);
  const hasApprovedBy = filteredData.some(item => item.approved_by);

  const LatestCreated = (item) => {
    const latestVersion = item?.version?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0];
    return latestVersion ? latestVersion.createdAt : item.createdAt;
  };
  if (filteredData.length === 0) {
    return (
      <p className="ms-2 mt-2">No {tableTitel}</p>
    );
  }
  return (
    <table
      className="table-bordered documents rounded-table p-0 "
      style={{ width: '100%', minWidth: '1200px' }}
    >
      <thead>
        <tr>
          <th scope="col" className="py-1 px-2 text-center align-middle">#</th>
          <th scope="col" className="py-1 px-2 text-center align-middle">Name</th>
          <th scope="col" className="py-1 px-2 text-center align-middle">Latest Version</th>
          <th scope="col" className="py-1 px-2 text-center align-middle">Created By</th>
          {tableTitel !== "Awaiting Compliance Review" && hasApprovedBy && (
            <th scope="col" className="py-1 px-2 text-center align-middle">Reviewed By</th>
          )}
          <th scope="col" className="py-1 px-2 text-center align-middle">Status</th>
          <th scope="col" className="py-1 px-2 text-center align-middle">Document Type</th>
          <th scope="col" className="py-1 px-2 text-center align-middle">Media Channel</th>
          <th scope="col" className="py-1 px-2 text-center align-middle">Target Geography</th>
          {/* <th scope="col" className="py-1 px-2 text-center align-middle">Description</th> */}
          <th scope="col" className="py-1 px-2 text-center align-middle">Age</th>
          {/* <th scope="col" className="py-1 px-2 text-center align-middle">Date Assigned</th> */}
        </tr>
      </thead>
      <tbody>
        {filteredData.map((item, index) => (
          <tr key={index}>
            <th scope="row" className="py-1 px-2 border">{index + 1}</th>
            <td className="py-1 px-2">
              <Link
                to={`/marketing/details/${Array.isArray(item?.version) && item.version.length > 0
                  ? item.version.reduce((latest, current) =>
                    Number(current.versionNumber || 0) > Number(latest.versionNumber || 0)
                      ? current
                      : latest
                  )?.versionId || item.documentId
                  : item?.documentId || "default-id"
                  }`}
                className="text-decoration-none text-dark"
                state={{
                  data:
                    Array.isArray(item?.version) && item.version.length > 0
                      ? item.version.reduce((latest, current) =>
                        Number(current.versionNumber || 0) > Number(latest.versionNumber || 0)
                          ? current
                          : latest
                      ) || item
                      : item || "default-id",
                  status: item.status,
                  firstVersion: item,
                }}
              >
                {item?.documentName || "Unknown Document"}
              </Link>
            </td>
            <td className="py-1 px-2 text-center align-middle">
              V
              {item?.version && item.version.length > 0
                ? item.version.reduce((latest, current) =>
                  Number(current.versionNumber) > Number(latest.versionNumber) ? current : latest
                ).versionNumber
                : item.firstVersion}
            </td>
            <td className="py-1 px-2 text-center align-middle">{item?.created_by}</td>
            {tableTitel !== "Awaiting Compliance Review" && hasApprovedBy && (
              <td className="py-1 px-2 text-center align-middle">{item?.approved_by}</td>
            )}
            <td className="py-1 px-2 text-center align-middle ">{item?.status}</td>
            <td className="py-1 px-2 text-center align-middle">{item?.documentType}</td>
            <td className="py-1 px-2 text-center align-middle">{item?.mediaChannel}</td>
            <td className="py-1 px-2 text-center align-middle">{item?.targetGeography}</td>
            {/* <td className="py-1 px-2 text-center align-middle">
                {formatDescription(item?.description)}
              </td> */}
            <td className="py-1 px-2 text-center align-middle">
              <AgeDisplay createdAt={LatestCreated(item)} />
            </td>
            {/* <td className="py-1 px-2">{LatestCreated(item).split("T")[0]}</td> */}
          </tr>
        ))}
      </tbody>
    </table>


  );
}
