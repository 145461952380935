import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { MarktingContext } from '../../Context/MarktingContext';
import { useNavigate } from 'react-router-dom';

export default function HTMLViewer({
  id,
  comments,
  setActiveComment,
  loginToken
}) {
  const { setMarktingData, marktingData, userName } = useContext(MarktingContext);

  const [content, setContent] = useState('');
  const [activeElementId, setActiveElementId] = useState(null);
  const [commentText, setCommentText] = useState('');
  const navigation = useNavigate();

  // Assign unique IDs to every element except the root element.
  // // Also, add a "cursor: pointer" style to indicate the element is clickable.
  // function assignIds(container) {
  //   const rootElement = container.firstElementChild;
  //   if (rootElement) {
  //     rootElement.removeAttribute('id');
  //   }
  //   const allElements = container.querySelectorAll('*');
  //   let counter = 1;
  //   allElements.forEach((element) => {
  //     if (element === rootElement) return;
  //     // Assign an id if it doesn't have one.
  //     if (!element.id) {
  //       element.id = `element-${counter}`;
  //       counter++;
  //     }
  //     // Set the cursor style to pointer for a better UX.
  //     element.style.cursor = 'pointer';
  //   });
  // }

  useEffect(() => {
    // AbortController to cancel the fetch if the component is unmounted.
    // Unmounted is when the component is removed from the DOM
    const controller = new AbortController();
    const fetchContent = async () => {
      try {

        const res = await axios.get(
          `https://us-central1-policy-portal-4ca94.cloudfunctions.net/credentialsFunc/content/${id}`,
          {
            headers: {
              Authorization: `Bearer ${loginToken}`,
            }
          }
        );
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = res?.data?.fileContent;
        // assignIds(tempDiv);
        setContent(tempDiv.innerHTML);
      } catch (error) {
        if (error?.response?.status === 401) {
          localStorage.removeItem("Firebase Auth");
          localStorage.removeItem("okta-token-storage");
          localStorage.removeItem("okta-cache-storage");
          navigation('/');
          // console.error("session expired");
          return;
      };
        if (error.name !== 'AbortError') {
          console.error('Error fetching content:', error);
        }
      }
    };

    fetchContent();
    return () => controller.abort(); // Cleanup function
  }, [id]);


  useEffect(() => {
    if (!content) return;

    let comments = [];
    const element = marktingData?.find((item) =>
      item?.documentId === id || item?.version?.some(version => version.versionId === id)
    );

    if (element) {
      comments = element.documentId === id
        ? element?.Comments ?? []
        : element?.version?.find(version => version.versionId === id)?.Comments ?? [];
    }

    // Batch update DOM
    const elementsToUpdate = [];
    comments?.forEach((comment) => {
      const element = document.getElementById(comment.elementId);
      if (element) {
        elementsToUpdate.push(element);
      }
    });

    elementsToUpdate.forEach(element => {
      element.style.outline = '2px solid #870eaf';
    });

  }, [content, marktingData, id]);


  // When an element is clicked, highlight it and show the comment form.
  const handleClick = (e) => {

    const clickedElement = e.target;

    

    if (!clickedElement.id) return; // Ignore clicks on elements without an ID

    setActiveComment({ elementId: clickedElement?.id });

    // Remove highlight from the previously active element if it isn't saved.
    if (activeElementId && activeElementId !== clickedElement.id) {
      // Here, we check in the comments array if a comment exists for the previous element.
      const prevSaved = comments?.find(
        (comment) => comment.elementId === activeElementId
      );
      if (!prevSaved) {
        const prevElement = document.getElementById(activeElementId);
        if (prevElement) prevElement.style.outline = '';
      }
    }

    // Highlight the clicked element using an outline.
    clickedElement.style.outline = '2px solid #870eaf';
    setActiveElementId(clickedElement.id);
    setCommentText(''); // Clear any previous comment text.
  };

  // Save the comment to local storage and leave the highlight.
  const handleSave = async () => {
    if (!activeElementId) return;
    const element = document.getElementById(activeElementId);
    if (!element) return;

    const payload = {
      elementId: activeElementId,
      comment: commentText.trim(),
    };

    try {
      const { data } = await axios.post(
        `https://us-central1-policy-portal-4ca94.cloudfunctions.net/credentialsFunc/comment`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${loginToken}`,
          },
          params: { id }, // Placed inside the same config object
        }
      );

      if (data?.data) {
        const deepClone = JSON.parse(JSON.stringify(marktingData));

        // Find the element with matching documentId or versionId
        const element = deepClone.find((item) =>
          item.documentId === id ||
          item.version?.some(version => version.versionId === id)
        );
        if (!element) {
          console.error('Element not found');
          return; // If no matching element, exit the function
        }


        if (element.documentId === id) {
          if (!element.Comments) {
            element.Comments = [data.data];
          } else {
            element.Comments.push(data.data);
          }
        } else {
          const version = element.version.find(v => v.versionId === id);
          version.Comments = version.Comments || [];
          version.Comments.push(data.data);
        }
        setMarktingData(deepClone);

      }
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.removeItem("Firebase Auth");
        localStorage.removeItem("okta-token-storage");
        localStorage.removeItem("okta-cache-storage");
        navigation('/');
        console.error("seassion expired");
        return;
    };
      console.error("Error saving comment:", error);
    }

    setActiveElementId(null);
    setCommentText('');
  };


  // Cancel the comment: remove the temporary highlight (if not saved) and hide the form.
  const handleCancel = () => {
    if (!activeElementId) return;
    // Check if there's already a saved comment for the active element.
    const savedCommentForElement = comments?.find(
      (comment) => comment.elementId === activeElementId
    );
    // If no saved comment exists, remove the outline.
    if (!savedCommentForElement) {
      const element = document.getElementById(activeElementId);
      if (element) {
        element.style.outline = '';
      }
    }
    setActiveElementId(null);
    setCommentText('');
  };

  return (
    <div>
      <div onClick={handleClick}>
        <div
          className="overflow-auto vh-100"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
      {activeElementId && (
        <div
          className="form-control"
          style={{
            position: 'fixed',
            bottom: '20px',
            left: '50%',
            transform: 'translateX(-50%)',
            padding: '10px',
            border: '1px solid #ccc',
            zIndex: 1000,
            width: '320px',
            boxShadow: '0 2px 5px rgba(0,0,0,0.3)',
          }}
        >
          <textarea
            value={commentText}
            onChange={(e) => setCommentText(e.target.value)}
            placeholder="Enter your comment here..."
            style={{ width: '100%', height: '130px' }}
          />
          <div className="d-flex gap-2 mt-1">
            <button
              className="btn btn-primary flex-grow-1"
              onClick={(e) => {
                e.stopPropagation();
                handleSave();
              }}
            >
              Save
            </button>
            <button
              className="btn btn-outline-danger"
              onClick={(e) => {
                e.stopPropagation();
                handleCancel();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
