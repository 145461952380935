import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
export default function SkeletonVendorPage({ location = "", data = {} }) {
  return (
    <table className="table-bordered documents rounded-table ms-1 p-0" style={{ marginTop: "30px" }}>
      <thead>
        <tr>
          <th scope="col" className="py-1 px-2">#</th>
          <th scope="col" className="py-1 px-2">Name</th>
          <th scope="col" className="py-1 px-2">{location === "Marketing Compliance" ? "Latest Version" : "Type"}</th>
          <th scope="col" className="py-1 px-2">{location === "Marketing Compliance" ? "Created By" : "Date Assigned"}</th>
          {location === "Marketing Compliance" && data?.tableTitel && <th scope="col" className="py-1 px-2">{data.tableTitel} By</th>}
          {location === "Marketing Compliance" && <th scope="col" className="py-1 px-2">Status</th>}
          <th scope="col" className="py-1 px-2">{location === "Marketing Compliance" ? "Date Assigned" : "Due Date"}</th>
        </tr>
      </thead>
      <tbody>
        <tr >
          <th scope="row" className="py-1 px-2 border">
            <Skeleton baseColor="#EBE6F0" highlightColor="#f5f5f5" width={location === "Marketing Compliance" ? 50 : 250} />
          </th>
          <th scope="row" className="py-1 px-2 border"><Skeleton baseColor="#EBE6F0" highlightColor="#f5f5f5" width={location === "Marketing Compliance" ? 190 : 250} /></th>
          <th scope="row" className="py-1 px-2 border"><Skeleton baseColor="#EBE6F0" highlightColor="#f5f5f5" width={location === "Marketing Compliance" ? 190 : 250} /></th>
          <th scope="row" className="py-1 px-2 border"><Skeleton baseColor="#EBE6F0" highlightColor="#f5f5f5" width={location === "Marketing Compliance" ? 190 : 250} /></th>
          {location === "Marketing Compliance" && data?.tableTitel && <th scope="row" className="py-1 px-2 border"><Skeleton baseColor="#EBE6F0" highlightColor="#f5f5f5" width={location === "Marketing Compliance" ? 190 : 250} /></th>}
          {location  === "Marketing Compliance" && <th scope="row" className="py-1 px-2 border"><Skeleton baseColor="#EBE6F0" highlightColor="#f5f5f5" width={location === "Marketing Compliance" ? 190 : 250} /></th>}
          <th scope="row" className="py-1 px-2 border"><Skeleton baseColor="#EBE6F0" highlightColor="#f5f5f5" width={location === "Marketing Compliance" ? 190 : 250} /></th>
        </tr>
      </tbody>
    </table>
  );
}

