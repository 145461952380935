import axios from 'axios';
import React, { useEffect, useState, useRef, useContext } from 'react';
import { MarktingContext } from '../../Context/MarktingContext';
import css from "./style.module.css";
import { useNavigate } from 'react-router-dom';


export default function ConvertedHtml({ id, comments, setActiveComment, loginToken }) {
  const containerRef = useRef(null);
  const iframeRef = useRef(null);
  const { setMarktingData, marktingData, userName } = useContext(MarktingContext);

  const [content, setContent] = useState('');
  const [activeElementId, setActiveElementId] = useState(null);
  const [commentText, setCommentText] = useState('');
  const [inputPosition, setInputPosition] = useState({ x: 0, y: 0 });
  const [localComments, setLocalComments] = useState([]);
  const [activeParentid, setActiveParentid] = useState(null);
  const previousActiveElementRef = useRef(null); // Track previous DOM element
  const navigation = useNavigate();
  // Update localComments from props when available
  useEffect(() => {
    if (comments) {
      setLocalComments(comments);
    }
  }, [comments]);

  // A ref to always track the latest localComments value
  const localCommentsRef = useRef(localComments);
  useEffect(() => {
    localCommentsRef.current = localComments;
  }, [localComments]);

  // function assignIds(container) {
  //   if (!container) return;
  //   // Remove 'id' from the first child of the container
  //   const rootElement = container.firstElementChild;
  //   if (rootElement) {
  //     rootElement.removeAttribute('id');
  //   }
  //   // Set cursor pointer for elements with ids starting with "pf" and matching hex pattern
  //   container.querySelectorAll("[id^='pf']").forEach(element => {
  //     if (/^pf[0-9a-fA-F]+$/.test(element.id)) {
  //       element.style.cursor = "pointer";
  //     }
  //   });
  // }

  function findParentWithHexId(element) {
    const hexRegex = /^pf[0-9a-fA-F]+$/;
    let current = element.parentElement;
    while (current) {
      if (hexRegex.test(current.id)) {
        return current;
      }
      current = current.parentElement;
    }
    return null;
  }

  // Fetch and prepare content from remote endpoint
  useEffect(() => {
    const controller = new AbortController();
    const fetchContent = async () => {
      try {
        const res = await axios.get(
          `https://us-central1-policy-portal-4ca94.cloudfunctions.net/credentialsFunc/content/${id}`,
          {
            headers: {
              Authorization: `Bearer ${loginToken}`,
            },
            signal: controller.signal
          }
        );
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = res?.data?.fileContent;
        // assignIds(tempDiv);
        setContent(tempDiv.innerHTML);
      } catch (error) {
        if (error?.response?.status === 401) {
          localStorage.removeItem("Firebase Auth");
          localStorage.removeItem("okta-token-storage");
          localStorage.removeItem("okta-cache-storage");
          navigation('/');
          console.error("seassion expired");
          return;
        };
        if (error.name !== 'AbortError') {
          console.error('Error fetching content:', error);
        }
      }
    };
    fetchContent();
    return () => controller.abort();
  }, [id]);

  // Write content into the iframe and attach click handler
  useEffect(() => {
    if (content && iframeRef.current) {
      const iframe = iframeRef.current;
      const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;

      iframeDoc.open();
      iframeDoc.write(content);
      iframeDoc.close();

      // Attach click handler to every element inside the iframe once loaded
      iframe.onload = () => {
        iframeDoc.querySelectorAll('*').forEach((element) => {
          element.onclick = handleClick;
        });
      };
    }
  }, [content]);

  // Process comments from marktingData to place icons into the iframe
  useEffect(() => {
    if (!content || !iframeRef.current) return;
    const iframeDoc = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;
    let fetchedComments = [];
    const element = marktingData?.find((item) =>
      item.documentId === id || item.version?.some(version => version.versionId === id)
    );
    if (element) {
      fetchedComments = element.documentId === id
        ? element.Comments ?? []
        : element.version.find(version => version.versionId === id)?.Comments ?? [];
    }
    setLocalComments(fetchedComments);

    fetchedComments.forEach(comment => {
      const el = iframeDoc.getElementById(comment.parentId) || iframeDoc.getElementById(comment.elementId);
      if (el) {
        const iconEl = iframeDoc.createElement("div");
        iconEl.style.position = "absolute";
        iconEl.style.left = comment.XLine + "px";
        iconEl.style.top = comment.YLine + "px";
        iconEl.style.margin = "0px";
        iconEl.style.transform = "translate(-50%, -50%)";
        iconEl.style.zIndex = "9999999"; // Avoid excessive z-index
        const svgHTML = comment.created_by === "PIRM AI" ? ""
          : `<svg fill="#6C757D" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 233.06 233.06">
          <g>
            <path id="${comment.elementId}" d="M116.538,4.05C52.284,4.05,0,45.321,0,96.043c0,28.631,16.729,55.208,45.889,72.911c4.525,2.737,7.635,7.283,8.572,12.478 
              c2.876,16.045-0.991,32.948-6.758,47.576c19.239-9.134,39.064-23.161,54.8-36.63c3.879-3.314,9.055-4.701,14.087-4.354h0.023 
              c64.191,0,116.445-41.259,116.445-91.987C233.058,45.321,180.792,4.05,116.538,4.05z"/>
          </g>
        </svg>`;

        iconEl.innerHTML = svgHTML;
        el.appendChild(iconEl);
      }
    });
  }, [content, marktingData, id]);

  // The click handler now uses localCommentsRef to always reference the latest state
  const handleClick = (e) => {
    const clickedElement = e.target;

    
    setActiveComment({ elementId: clickedElement?.id });

    const parent = findParentWithHexId(clickedElement);

    if (parent) {
      let formattedId;
      do {
        // Generate a 3-digit random number between 100 and 999
        const randomId = Math.floor(Math.random() * 900) + 100;
        formattedId = String(randomId);
      } while (
        localCommentsRef.current.some(
          comment => comment.elementId === `${clickedElement.id}_p${formattedId}`
        )
      );
      setActiveParentid(parent.id);
      setActiveElementId(`${parent.id}${formattedId}`);

      const parentRect = parent.getBoundingClientRect();
      const relativeX = e.clientX - parentRect.left;
      const relativeY = e.clientY - parentRect.top;
      setInputPosition({ x: relativeX, y: relativeY });
    }

    if (previousActiveElementRef.current) {
      previousActiveElementRef.current.style.fill = '#6C757D';
      const isComment = localCommentsRef.current.some(
        c => c.elementId === previousActiveElementRef.current.id
      );

      if (isComment && clickedElement) {


        clickedElement.style.fill = '#870eaf';
      }
    }
    previousActiveElementRef.current = clickedElement;
    setCommentText('');
  };

  const handleSave = async () => {
    if (!activeElementId || !previousActiveElementRef.current) return;
    const element =
      iframeRef.current.contentDocument.getElementById(activeElementId) ||
      iframeRef.current.contentDocument.getElementById(activeParentid);
    if (!element) return;

    const payload = {
      parentId: activeParentid,
      elementId: activeElementId,
      comment: commentText.trim(),
      XLine: inputPosition.x || 0,
      YLine: inputPosition.y || 0,
    };

    try {
      const { data } = await axios.post(
        `https://us-central1-policy-portal-4ca94.cloudfunctions.net/credentialsFunc/comment`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${loginToken}`,
          },
          params: { id }, // Placed inside the same config object
        }
      );

      if (data?.data) {
        // Update local comments immediately using the functional update
        setLocalComments(prevComments => [...prevComments, data.data]);

        const deepClone = JSON.parse(JSON.stringify(marktingData));
        const elementToUpdate = deepClone.find(
          item => item.documentId === id || item.version?.some(version => version.versionId === id)
        );
        if (!elementToUpdate) return;
        if (elementToUpdate.documentId === id) {
          elementToUpdate.Comments = elementToUpdate.Comments || [];
          elementToUpdate.Comments.push(data.data);
        } else {
          const version = elementToUpdate.version.find(v => v.versionId === id);
          version.Comments = version.Comments || [];
          version.Comments.push(data.data);
        }

        setMarktingData(deepClone);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.removeItem("Firebase Auth");
        localStorage.removeItem("okta-token-storage");
        localStorage.removeItem("okta-cache-storage");
        navigation('/');
        return;
      };
      console.error("Error saving comment:", error);
    }

    if (previousActiveElementRef.current) {
      const isImageComment =
        previousActiveElementRef.current.tagName === "P" &&
        previousActiveElementRef.current.id.includes("image");
      const isExistingComment = localCommentsRef.current.some(
        c => c.elementId === previousActiveElementRef.current.id
      );
      if (!isExistingComment) {
        if (isImageComment) {
          previousActiveElementRef.current.style.color = '';
        } else {
          previousActiveElementRef.current.style.outline = '';
        }
      }
    }

    setActiveElementId(null);
    setCommentText('');
  };

  const handleCancel = () => {
    if (!activeElementId) return;
    const savedComment = comments?.find(
      comment => comment.elementId === activeElementId
    );
    if (!savedComment) {
      previousActiveElementRef.current.style.outline = '';
      previousActiveElementRef.current = null;
    }
    setActiveElementId(null);
    setCommentText('');
  };

  return (
    <div ref={containerRef} style={{ position: 'relative' }}>
      <iframe
        ref={iframeRef}
        id="myIframe"
        title="content-frame"
        className="w-100 vh-100"
      />
      {activeElementId && (
        <div
          className="form-control"
          style={{
            position: 'fixed',
            bottom: '20px',
            left: '50%',
            transform: 'translateX(-50%)',
            padding: '10px',
            border: '1px solid #ccc',
            zIndex: 1000,
            width: '320px',
            boxShadow: '0 2px 5px rgba(0,0,0,0.3)',
          }}
        >
          <textarea
            value={commentText}
            onChange={e => setCommentText(e.target.value)}
            placeholder="Enter your comment here..."
            style={{ width: '100%', height: '130px' }}
          />
          <div className="d-flex gap-2 mt-1">
            <button
              className="btn btn-primary flex-grow-1"
              onClick={e => {
                e.stopPropagation();
                handleSave();
              }}
            >
              Save
            </button>
            <button
              className="btn btn-outline-danger"
              onClick={e => {
                e.stopPropagation();
                handleCancel();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
}