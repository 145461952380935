import React, { useContext, useEffect, useState } from "react";
import css from "./style.module.css";
import axios from "axios";
import { SalesforceContext } from "../../Context/SF.CredantialsContext";
import SkeletonQuestionsPage from "../LoadingPages/SkeletonQuestionsPage";
import { useNavigate } from "react-router-dom";
import DOMPurify from 'dompurify';
import { fileSchema } from "../../lib/Yup Validation/VendorsSchemas";
import SavingOverlay from "./SavingOverlay";

export default function Questions({ questionnaire, position, id, Audits_r, pirm_addon__Status__c, setAllQuestions, allQuestions, TestClickUpdate, updateAnswer, DeleteFileParent, handleClick, questionnaireLength, theAnswerdQuestion, theTotalQuestion, TestClickUpdateMultSelect }) {
    const [data, setData] = useState([]); // Stores the questionnaire data
    const [fileName, setFileName] = useState({}); // Tracks uploaded file names
    const [filesBase64, setFilesBase64] = useState({}); // Tracks uploaded files with Base64 content
    const [saveSpinner, setSaveSpinner] = useState(false);
    const [updateSpinner, setUpdateSpinner] = useState(false);
    const [error, setError] = useState('');
    const { tokens, fetchCredentials } = useContext(SalesforceContext);
    const [isActive, setIsActive] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [answerd, setAnswerd] = useState(false);
    const [autoSave, setAutoSave] = useState(false);
    const navigate = useNavigate()
    useEffect(() => {
        if (questionnaire) {
            const clonedData = JSON.parse(JSON.stringify(questionnaire)); // Deep clone to avoid mutating props
            setData(clonedData);
        }
    }, [questionnaire, position]);

    if (!data.length) return <SkeletonQuestionsPage />;

    const openModal = () => {
        setIsVisible(true); // Show the modal
        setTimeout(() => setIsActive(true), 10); // Add active class after a slight delay

    };
    const closeModal = () => {
        setIsActive(false); // Remove active class
        setTimeout(() => setIsVisible(false), 500); // Hide modal after transition

        navigate("/vendors", { replace: true }); // Navigate to /vendors
        setTimeout(() => {
            window.location.reload(); // Force a page reload
        }, 100); // Delay slightly to ensure navigation completes
    };
    // file
    const convertFileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const base64String = reader.result.split(',')[1];
                resolve(base64String);  // Resolve the promise with the base64 string
            };
            reader.onerror = () => {
                reject("Error reading file");  // Reject the promise on error
            };
        });
    };

    const handleFileChange = async (event, questionId) => {
        try {
            setError('');
            const file = event.target.files[0];


            if (file) {
                await fileSchema.validate({ file });

                const maxSize = 50 * 1024 * 1024;  // 50MB in bytes
                if (file.size > maxSize) {
                    setError("File size exceeds the maximum limit of 50MB.");
                    return;
                }


                try {
                    const base64String = await convertFileToBase64(file);  // Wait for the base64 conversion
                    const name = file.name;  // Directly access file.name


                    setFileName((prev) => ({
                        ...prev,
                        [questionId]: file.name,
                    }));
                    // Call submitAnswers after the file is successfully converted to base64
                    await submitAnswers("not updated", true, base64String, questionId, name);
                } catch (error) {
                    console.error("Error to send file:", error);
                    setFileName((prev) => ({
                        ...prev,
                        [questionId]: "Error to send file",
                    }));
                }
            } else {
                setFilesBase64((prev) => ({
                    ...prev,
                    [questionId]: null,
                }));
                setFileName((prev) => ({
                    ...prev,
                    [questionId]: "No file chosen",
                }));
            }
        } catch (error) {
            setError(error.errors[0] || 'An unexpected error occurred. Please try again.');
        }
    };



    // const handleFileCancel = (questionId) => {
    //     setFilesBase64((prev) => {
    //         const NewFilesBase64 = { ...prev };
    //         delete NewFilesBase64[questionId];
    //         return NewFilesBase64;
    //     });
    //     setFileName((prev) => {
    //         const NewFileName = { ...prev };
    //         delete NewFileName[questionId];
    //         return NewFileName;
    //     });

    //     // Manually reset the file input element value to allow selecting a new file
    //     const fileInput = document.getElementById(questionId);
    //     if (fileInput) {
    //         fileInput.value = ""; // Clear the input field
    //     }
    // };
    const handleDeleteAll = () => {
        setFilesBase64({});
        setFileName({});

        // Optionally, reset the file input elements to clear them
        const fileInputs = document.querySelectorAll('input[type="file"]');
        fileInputs.forEach((input) => {
            input.value = ""; // Clear the input field
        });
    };

    const checkForUnansweredQuestions = () => {
        const filteredQuestions = allQuestions.filter(
            (e) => e.pirm_addon__IsApplicable__c === true && e.pirm_addon__QuestionType__c !== "Blocker"
        );

        // Check if any question is unanswered
        const hasUnanswered = filteredQuestions.some((e) => {
            const isAnswerEmpty =
                e.pirm_addon__Answer__c === "" || // Empty string
                e.pirm_addon__Answer__c === null || // Null value
                (typeof e.pirm_addon__Answer__c === 'string' && e.pirm_addon__Answer__c.trim() === ""); // Whitespace-only strings

            // Check if ContentDocumentLinks is empty or undefined
            const hasNoFiles =
                !e.ContentDocumentLinks || // Undefined or null
                e.ContentDocumentLinks.length === 0; // Empty array

            return isAnswerEmpty && hasNoFiles;
        });

        return hasUnanswered; // Return true if any question is unanswered
    };

    // Handle submit button click
    const handleSubmitClick = async () => {

        if (checkForUnansweredQuestions()) {
            setAnswerd(false); // Indicate not all questions are answered
        } else {

            setAnswerd(true); // Indicate all questions are answered
        }
    };



    // submit & save  
    const submitAnswers = async (action, uploadFiles = false, base64String = null, questionId = null, name = null, allQuestionsUpdated = null) => {
        if (action !== "updated") {
            setAutoSave(true);

        };
        setError('');
        if (action === "updated") setUpdateSpinner(true);
        else setSaveSpinner(true);
        let payload;
        if (uploadFiles) {

            payload = createFilePayload(base64String, questionId, name);
        } else {
            payload = createPayload(allQuestionsUpdated);
        }

        // Build payload
        try {
            if (uploadFiles) {
                await processRequestForFile(payload, tokens);
            } else {
                await processRequest(action, payload, tokens);
            }
        } catch (error) {
            if (isTokenExpiredError(error)) {
                try {
                    const tokenRefreshed = await refreshToken(); // Refresh token and check if it worked

                    if (tokenRefreshed) {
                        await processRequest(action, payload, tokenRefreshed); // Retry request with new token
                    } else {
                        throw new Error("Token refresh failed. Please log in again.");
                    }
                } catch (retryError) {
                    handleRequestError(retryError, true);
                }
            } else {
                handleRequestError(error);
            }
        } finally {
            setAutoSave(false);

            handleDeleteAll();
            if (action === "updated") setUpdateSpinner(false);
            else {
                setSaveSpinner(false);
            };
        }
    };
    // Helper function to check if error is token expiration related
    const isTokenExpiredError = (error) => {
        return error.response?.status === 401 || error.response?.data?.message === "Token expired";
    };

    // Helper function to refresh token
    const refreshToken = async () => {
        try {
            const newToken = await fetchCredentials(); // Example function for refreshing token
            if (newToken) {
                localStorage.setItem('authToken', newToken); // Store the new token
                return newToken; // Indicate that the token was refreshed successfully
            }
            return false; // If no token was returned, refresh failed
        } catch (error) {
            console.error("Error refreshing token:", error);
            return false; // Indicate failure
        }
    };

    // Helper function to handle errors
    const handleRequestError = (error, isRetry = false) => {
        console.error("Error updating answers or uploading files:", error.message);
        setError(isRetry ? "Token refresh failed. Please log in again." : error.response?.data?.message || "An error occurred");
    };

    // Helper function to create payload
    const createPayload = (allQuestionsUpdated) => {
        // Ensure `item.pirm_addon__Answer__c` exists before mapping

        const arrayToMap = allQuestionsUpdated || allQuestions;
        const updatesArray = arrayToMap.map((item) => ({
            recordId: item.Id,
            newAnswer: item.pirm_addon__Answer__c || null, // Fallback to null if undefined
            isApplicable: item.pirm_addon__IsApplicable__c,
        }));
        // Build file payload

        return {
            newAnswer: updatesArray,
        };
    };
    const createFilePayload = (base64String, questionId, file) => {


        // Get the file name from the file object (assuming file is an object with a 'name' property)
        const fileNameForPayload = file || "unknown"; // Use "unknown" if file.name is not available

        // Return the file payload with recordId (questionId) and the fileName
        const filesPayload = [
            {
                recordId: questionId, // Use the questionId as recordId
                fileName: fileNameForPayload, // Correct fileName
                fileContent: base64String || "", // Default empty content if base64String is null
            }
        ];
        return { files: filesPayload };
    };

    // Helper function to process request
    const processRequest = async (action, payload, tokens) => {
        // Audits_r is the audit pirm_addon__Audits__r
        const response = await axios.post(
            `https://api-skhwrpqgyq-uc.a.run.app/UpdateAnswer/${id}/${Audits_r}?action=${action}`,
            payload,
            {
                headers: {
                    Authorization: `Bearer ${tokens}`,
                },
            }
        );

        if (response.status === 200) {

            updateQuestionsState(payload, response.data.files, response.data.allQuestions); // Pass payload and files here
        } else {
            throw new Error(`Failed with status: ${response.status}`);
        }
    };

    const processRequestForFile = async (payload, tokens) => {


        const response = await axios.post(
            `https://api-skhwrpqgyq-uc.a.run.app/UpdateAnswer/files`,
            payload,
            {
                headers: {
                    Authorization: `Bearer ${tokens}`,
                },
            }
        );

        if (response.status === 200) {

            updateQuestionsState({}, response.data.files); // Only pass the files here
        } else {
            throw new Error(`Failed with status: ${response.status}`);
        }
    };

    // Helper function to update questions state
    const updateQuestionsState = (payload = {}, files = {}, response = []) => {
        const fileArray = Object.entries(files).map(([id, array]) => ({
            id,
            array,
        }));
        if (Object.keys(files).length > 0) {
            const updatedQuestions = allQuestions.map((question) => {
                // Get the matching answer
                const matchingAnswer = payload.newAnswer?.find((answer) => answer.recordId === question.Id);

                // Get the matching file
                const matchingFile = fileArray.find((file) => file.id === question.Id);

                // Handle the ContentDocumentLinks if there's a file
                const ContentDocumentLinks = matchingFile
                    ? {
                        totalSize: matchingFile.array.length,
                        done: true,
                        records: matchingFile.array.map((file) => ({
                            attributes: {
                                type: "ContentDocumentLink",
                                url: `/services/data/v50.0/sobjects/ContentDocumentLink/${file.ContentDocument.Id}`,
                            },
                            ContentDocument: {
                                attributes: {
                                    type: "ContentDocument",
                                    url: `/services/data/v50.0/sobjects/ContentDocument/${file.ContentDocument.Id}`,
                                },
                                Title: file.ContentDocument.Title,
                                Id: file.ContentDocument.Id,
                            },
                        })),
                    }
                    : null;

                // Return the updated question, merging answers and files
                return {
                    ...question,
                    pirm_addon__Answer__c: matchingAnswer ? matchingAnswer.newAnswer : question.pirm_addon__Answer__c,
                    ContentDocumentLinks: ContentDocumentLinks || question.ContentDocumentLinks,
                };
            });

            setAllQuestions(updatedQuestions); // Update state with the updated questions
        } else {
            response?.sort((a, b) => {
                const aParentTemplate =
                    a.pirm_addon__Question__r?.pirm_addon__Template__r
                        ?.pirm_addon__Parent_Template__c;
                const bParentTemplate =
                    b.pirm_addon__Question__r?.pirm_addon__Template__r
                        ?.pirm_addon__Parent_Template__c;

                if (!aParentTemplate && bParentTemplate) return -1;
                if (aParentTemplate && !bParentTemplate) return 1;
                return 0;
            });

            setAllQuestions(response); // Update state with the updated questions
        }
    };


    // Define the component
    function QuestionComponent({ questionHtml }) {
        // Sanitize the HTML
        const sanitizedHtml = DOMPurify.sanitize(questionHtml);
        return (
            <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
        );
    }
    return (
        <>
            <div className="ps-5">
                {data.map((testedData, index) => {
                    if (testedData?.pirm_addon__QuestionType__c === "Picklist") {
                        return (
                            <div key={index} className="d-flex my-4">
                                <p className="m-0 me-1">{index + 1}-</p>
                                <div>
                                    {testedData.pirm_addon__Question_Text_Rich__c ? (
                                        <QuestionComponent questionHtml={testedData.pirm_addon__Question_Text_Rich__c} />
                                    ) : (
                                        <p className="fs-6 m-0">{testedData.pirm_addon__Question_Text__c || " "}</p>
                                    )}

                                    {JSON.parse(testedData.pirm_addon__Question__r.pirm_addon__Possible_Responses__c).map(
                                        (response, idx) => (
                                            <div className="d-block mt-2" key={idx}>
                                                <input
                                                    className="custom-input"
                                                    name={`${testedData.Id}`}
                                                    type="radio"
                                                    checked={testedData.pirm_addon__Answer__c?.includes(response.name)}
                                                    readOnly
                                                    disabled={pirm_addon__Status__c === "Completed"} // Disable input if completed
                                                />
                                                <span
                                                    className="custom-checkmark"
                                                    onClick={async () => {
                                                        const result = pirm_addon__Status__c !== "Completed" &&
                                                            TestClickUpdate(testedData, response.name)
                                                        pirm_addon__Status__c !== "Completed" && await submitAnswers("not Updated", false, null, null, null, result);
                                                    }
                                                    } // Prevent onClick if completed
                                                ></span>
                                                <span className="custom-input-label">{response.name}</span>
                                            </div>
                                        )
                                    )}
                                </div>
                            </div>
                        );
                    } else if (testedData?.pirm_addon__QuestionType__c === "Multi-Select Picklist") {
                        const possibleResponses = JSON.parse(testedData.pirm_addon__Question__r.pirm_addon__Possible_Responses__c);

                        let selectedAnswers = [];
                        try {
                            selectedAnswers = Array.isArray(testedData.pirm_addon__Answer__c)
                                ? testedData.pirm_addon__Answer__c
                                : JSON.parse(testedData.pirm_addon__Answer__c || "[]");
                        } catch (e) {
                            selectedAnswers = [];
                            console.error("Error parsing answers, falling back to empty array");
                        }

                        return (
                            <div key={index} className="d-flex my-4">
                                <p className="m-0 me-1">{index + 1}-</p>
                                <div>
                                    {testedData.pirm_addon__Question_Text_Rich__c ? (
                                        <QuestionComponent questionHtml={testedData.pirm_addon__Question_Text_Rich__c} />
                                    ) : (
                                        <p className="fs-6 m-0">{testedData.pirm_addon__Question_Text__c || " "}</p>
                                    )}

                                    {possibleResponses.map((response, idx) => (
                                        <label className="d-block mt-2" key={idx}>
                                            <input
                                                className="custom-input"
                                                name={`${testedData.Id}-${idx}`}
                                                type="checkbox"
                                                checked={selectedAnswers.includes(response.name)} // Check if this answer is selected
                                                onChange={() =>
                                                    pirm_addon__Status__c !== "Completed" &&
                                                    TestClickUpdateMultSelect(testedData, response.name) // Pass isMultiSelect = true
                                                }
                                                disabled={pirm_addon__Status__c === "Completed"} // Disable checkbox if completed
                                            />
                                            <span className="custom-checkmark"></span>
                                            <span className="custom-input-label">{response.name}</span>
                                        </label>
                                    ))}
                                </div>
                            </div>
                        );
                    }
                    else if (testedData?.pirm_addon__QuestionType__c === "Document Upload") {
                        return (
                            <div key={index} className="mb-3 d-flex my-4">
                                <p className="m-0 me-1">{index + 1}-</p>
                                <div>
                                    {testedData.pirm_addon__Question_Text_Rich__c ? (
                                        <QuestionComponent questionHtml={testedData.pirm_addon__Question_Text_Rich__c} />
                                    ) : (
                                        <p className="fs-6 m-0">{testedData.pirm_addon__Question_Text__c || " "}</p>
                                    )}
                                    <div className="d-flex mt-2">
                                        <input
                                            type="file"
                                            id={testedData.Id}
                                            onChange={(event) => handleFileChange(event, testedData.Id)}
                                            style={{ display: "none" }}
                                        />
                                        {pirm_addon__Status__c !== "Completed" && (
                                            <>
                                                <label htmlFor={testedData.Id} className={`btn ${css.btnFiles}`}>
                                                    Choose File
                                                </label>
                                                <p className="my-auto ms-2">
                                                    {fileName[testedData.Id] || "No file chosen"}
                                                </p>

                                                {/* Render the "X" button only if a file is selected */}
                                                {/* {fileName[testedData.Id] && fileName[testedData.Id] !== "No file chosen" && (
                                                <button
                                                    type="button"
                                                    onClick={() => handleFileCancel(testedData.Id)} // Cancel the file
                                                    className="ms-2 btn text-danger"
                                                >
                                                    X
                                                </button>
                                            )} */}
                                            </>
                                        )}
                                    </div>

                                    {/* Existing code for displaying files from ContentDocumentLinks */}
                                    {testedData?.ContentDocumentLinks?.records?.length > 0 ? (
                                        testedData?.ContentDocumentLinks.records.map((file, idx) => (
                                            <div key={idx} className="mt-2">
                                                {pirm_addon__Status__c !== "Completed" && (
                                                    <i
                                                        className={`fa-solid fa-trash fa-fw me-2 holdCursor ${css.trash}`}
                                                        onClick={() => {
                                                            DeleteFileParent(testedData, file.ContentDocument.Id, tokens);
                                                        }}
                                                    ></i>
                                                )}
                                                {file.ContentDocument.Title}
                                            </div>
                                        ))
                                    ) : (
                                        <p>No files available for this question.</p>
                                    )}
                                </div>
                            </div>
                        );
                    }

                    else if (testedData?.pirm_addon__QuestionType__c === "Free Text") {
                        return (
                            <div key={index} className="d-flex my-4">
                                <p className="m-0 me-1">{index + 1}-</p>
                                <div className=" " style={{ width: "93%" }}>
                                    {testedData.pirm_addon__Question_Text_Rich__c ? (
                                        <QuestionComponent questionHtml={testedData.pirm_addon__Question_Text_Rich__c} />
                                    ) : <p className=" fs-6 m-0"> {testedData.pirm_addon__Question_Text__c || " "}</p>}
                                    <input
                                        type="text"
                                        name={testedData.Id}
                                        className={`${css.inputText} border-0 w-100 `}
                                        placeholder="Enter Your Answer"
                                        value={testedData.pirm_addon__Answer__c || ""}
                                        onChange={(e) => {
                                            if (e.target.value !== " ") {
                                                updateAnswer(testedData, e.target.value)
                                            }
                                        }}
                                        readOnly={pirm_addon__Status__c === "Completed"}
                                    />
                                </div>
                            </div>
                        );
                    } else {
                        return (
                            testedData.pirm_addon__Question_Text_Rich__c ? (
                                <div key={index} className="text-danger fw-bold">
                                    <QuestionComponent questionHtml={testedData.pirm_addon__Question_Text_Rich__c} />
                                </div>
                            ) : (
                                <div key={index} className="text-danger fw-bold">
                                    {testedData?.pirm_addon__Question_Text__c}
                                </div>
                            )
                        );
                    }
                })}
                {/* pages and total  */}
                <>
                    <div className="d-flex align-items-center ">
                        <div className="">
                            {questionnaireLength > 1 && (
                                <>
                                    {position === 0 ? (
                                        <button
                                            className={`btn ${css.nextSectionbtn} text-white`}
                                            onClick={() => handleClick(position + 1)}>
                                            Next Section
                                        </button>
                                    ) : position === questionnaireLength - 1 ? (
                                        <button
                                            className={`btn ${css.nextSectionbtn} text-white`}
                                            onClick={() => handleClick(position - 1)}>
                                            Prev Section
                                        </button>
                                    ) : (
                                        <>
                                            <button
                                                className={`btn ${css.nextSectionbtn} text-white me-2`}
                                                onClick={() => handleClick(position - 1)}>
                                                Prev Section
                                            </button>
                                            <button
                                                className={`btn ${css.nextSectionbtn} text-white`}
                                                onClick={() => handleClick(position + 1)}>
                                                Next Section
                                            </button>
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                        {pirm_addon__Status__c !== "Completed" && (
                            <>
                                {theAnswerdQuestion === theTotalQuestion && (
                                    <button
                                        onClick={async () => {
                                            await handleSubmitClick();
                                        }}
                                        className="btn btn-success text-white ms-2 holdCursor"
                                    >
                                        Submit
                                        {updateSpinner && (
                                            <i className="ms-3 fas fa-spinner fa-pulse "></i>
                                        )}
                                    </button>
                                )}



                                <p onClick={async () => await submitAnswers("not Updated")} className="m-0 pt-0  holdCursor upload-btn-wrapper ms-2">Save Draft

                                    {saveSpinner && <i className="ms-1 fas fa-spinner fa-pulse"></i>}
                                </p>


                            </>
                        )}
                    </div>

                    <p className='fw-semibold'>
                        Total Answered Questions:  {theAnswerdQuestion}/ {theTotalQuestion}
                    </p>
                </>
                {pirm_addon__Status__c !== "Completed" && (
                    <>
                        {
                            <>

                                {answerd && (
                                    <div className="bg-light-black position-fixed top-0 start-0 bottom-0 end-0">
                                        <div className="bg-white px-5 rounded-4 position-absolute top-50 start-50 translate-middle text-center">
                                            <p className="text-dark  my-5 mb-4" style={{ fontWeight: "500" }}>
                                                Once you submit this questionnaire you will not be able to make
                                                any additional updates. <br /> Are you sure you want to submit?
                                            </p>
                                            <button
                                                className="btn btn-danger me-3 mb-4"
                                                onClick={() => setAnswerd(false)}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                className="btn btn-success mb-4"
                                                onClick={async () => {

                                                    await submitAnswers("updated");
                                                    openModal();
                                                }}
                                            >
                                                Submit{" "}
                                                {updateSpinner && (
                                                    <i className="ms-3 fas fa-spinner fa-pulse"></i>
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                )}

                                {/*  */}
                                {error && <p className="text-danger">{error}</p>}
                            </>

                        }
                    </>
                )
                }
                {
                    isVisible && (
                        <div className="modal-overlay">
                            <div className={`model-prop ${isActive ? 'active' : ''}`}>
                                <div className="model-heading">
                                    <h3 className="m-0">Finished </h3>
                                </div>
                                <div className="model-body">
                                    <p>
                                        Thank you for completing the questionnaire.
                                        <br />
                                        It has been submitted to the Vendor Management team for review.
                                    </p>
                                </div>
                                <div className="model-footer">
                                    <button className="btn btn-primary px-4 py-2" onClick={closeModal}>
                                        Done
                                    </button>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div >
            {autoSave && <SavingOverlay />}
        </>
    );
}