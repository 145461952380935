import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import NavBar from '../NavBar/NavBar';
import { SalesforceContext } from "../../Context/SF.CredantialsContext";
import PDF from './PDF/PDF';
import SkeletonPDF from '../LoadingPages/SkeletonPDF';
import css from './style.module.css';
import { motion } from "framer-motion";

export default function PoliciesDetails() {
    const { name, id } = useParams();
    const [details, setDetails] = useState(null);
    const { tokens, fetchCredentials } = useContext(SalesforceContext);
    const { protocol, hostname, port } = window.location;
    let fullUrl = `${protocol}//${hostname}${port ? `:${port}` : ""}/`;
    // check if the document is acknowledged or not to handle the button
    const [isAcknowledged, setIsAcknowledged] = useState(false);
    const modalContentRef = useRef(null);

    const handleAcknowledgeChange = (e) => {
        setIsAcknowledged(e.target.checked);
    };
    const handleSave = () => {
        if (isAcknowledged) {
            const timestamp = new Date().toISOString();
            // onSave({ acknowledged: true, timestamp });
            // setIsModalOpen(false);
            alert("Your acknowledgment has been saved.");
        }
    };
    useEffect(() => {
        const fetchData = async () => {
            if (!tokens || !id) return;
            try {
                const { data } = await axios.post(
                    `https://api-skhwrpqgyq-uc.a.run.app//polices/${id}`,
                    {}, // No body needed for POST request
                    {
                        headers: {
                            Authorization: `Bearer ${tokens}`,
                        }
                    }
                );
                setDetails(data);
            } catch (error) {
                if (
                    error.response.data.message === "Token expired" &&
                    error?.response?.status === 401
                ) {
                    await fetchCredentials();
                } else console.error("Error fetching data:", error);

            }
        };
        fetchData();
    }, [tokens, id]); // Only fetch when tokens or id change

    return (
        
            <div className="container-fluid pt-3 mb-3 ">
                <div className={`bg-white mb-3 rounded-2 d-flex documents ${css.documentHeader} overflow-hidden d-flex align-items-center justify-content-between `}>
                    <motion.div
                        className={` m-0 py-2 ms-3 ${css.headerText}`}
                        initial={{ x: -100, y: 0 }}
                        animate={{ x: 0, y: 0 }}
                        transition={{
                            type: "spring",
                            damping: 20,    // More damping to smooth out the movement
                            duration: 1,    // Adjust duration for slower speed
                        }}
                    >
                        <h5 className='m-0'>{name}</h5>
                    </motion.div>
                    {(fullUrl === "http://localhost:3000/" || fullUrl === "https://policy-portal-4ca94.firebaseapp.com/") &&
                        <div className=' d-flex align-items-center '>
                            <div>
                                <label className="custom-checkbox d-flex align-items-center" >
                                    <input
                                        className="custom-input "
                                        type="checkbox"
                                        checked={isAcknowledged}
                                        onChange={handleAcknowledgeChange}

                                    />


                                    <span className="custom-checkmark "></span>
                                    <span className="custom-input-label m-0 p-0 fw-bold">
                                        I have read and understood the policy.
                                    </span>

                                </label>
                            </div>
                            <button
                                onClick={handleSave}
                                disabled={!isAcknowledged}
                                style={{
                                    padding: "12px 12px",
                                    backgroundColor:
                                        isAcknowledged ? "#691DF8" : "#ccc",
                                    color: "white",
                                    border: "none",
                                    borderRadius: "0px 5px 5px 0px",
                                    cursor:
                                        isAcknowledged
                                            ? "pointer"
                                            : "not-allowed",
                                }}
                                className='ms-2'
                            >

                                Save
                            </button>
                        </div>
                    }
                </div>

                {details ?

                    <div
                        className="vh-100 "
                    // initial={{ opacity: 0, y: -400 }}
                    // animate={{ opacity: 1, y: 0 }}
                    // transition={{ type: "spring", stiffness: 50, damping: 20, duration: 1 }}
                    >
                        <PDF name={name} details={details} />
                    </div>

                    : <div className="vh-100">
                        <SkeletonPDF />
                    </div>
                }
            </div>
        
    );
}

