import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { MarktingContext } from "../../Context/MarktingContext";
import SkeletonPDF from "../LoadingPages/SkeletonPDF";
import css from "./style.module.css";
import { useNavigate } from "react-router-dom";

export default function ImageComponent({
    id,
    imgUrl,
    loginToken,
    comments,
    setActiveComment,
}) {
    const { setMarktingData, marktingData, userName } = useContext(MarktingContext);

    const [imageUrl, setImageUrl] = useState("");
    const [showInput, setShowInput] = useState(false);
    const [inputPosition, setInputPosition] = useState({ x: 0, y: 0 });
    const [visibleCommentId, setVisibleCommentId] = useState(null);
    const [size, setSize] = useState({ width: 0, height: 0 });
    const [currentCommentText, setCurrentCommentText] = useState('');


    const containerRef = useRef(null);
    const imageRef = useRef(null);

const navigation = useNavigate();

    const updateSize = () => {
        if (imageRef.current) {
            setSize({
                width: imageRef.current.offsetWidth,
                height: imageRef.current.offsetHeight,
            });
        }
    };

    useEffect(() => {
        if (imgUrl) {
            setImageUrl(imgUrl);
        }
    }, [imgUrl]);

    const handleContentClick = (e) => {
        if (!imageRef.current) return;

        const rect = imageRef.current.getBoundingClientRect();
        const relativeX = e.clientX - rect.left;
        const relativeY = e.clientY - rect.top;

        setInputPosition({ x: relativeX, y: relativeY });
        setShowInput(true);
    };

    const handleSaveComment = async (comment) => {
        try {
            if (!comment.trim()) return;

            const payload = {
                XLine: inputPosition.x,
                YLine: inputPosition.y,
                screenWidth: size.width,
                screenHeight: size.height,
                comment: comment.trim(),
                created_by: userName,
            };

            const { data } = await axios.post(
                `https://us-central1-policy-portal-4ca94.cloudfunctions.net/credentialsFunc/comment`,
                payload,
                { headers: { Authorization: `Bearer ${loginToken}` }, params: { id } }
            );


            if (data?.data) {
                const deepClone = JSON.parse(JSON.stringify(marktingData));

                // Find the element with matching documentId or versionId
                const element = deepClone.find((item) =>
                    item.documentId === id ||
                    item.version?.some(version => version.versionId === id)
                );


                if (!element) {
                    console.error('Element not found');
                    return; // If no matching element, exit the function
                }

                if (element.documentId === id) {
                    if (!element.Comments) {
                        element.Comments = [data.data];
                    } else {
                        element.Comments.push(data.data);
                    }
                } else {
                    const version = element.version.find(v => v.versionId === id);
                    version.Comments = version.Comments || [];
                    version.Comments.push(data.data);
                }
                setMarktingData(deepClone);
            }

            setShowInput(false);
        } catch (error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem("Firebase Auth");
                localStorage.removeItem("okta-token-storage");
                localStorage.removeItem("okta-cache-storage");
                navigation('/');
                console.error("seassion expired");
                return;
            };
            console.error("error => ", error);
        }
    };

    if (!imageUrl) {
        return <SkeletonPDF />;
    }

    return (
        <div
            ref={containerRef}
            style={{
                position: "relative",
                maxHeight: "100vh",
                overflowY: "auto",
                padding: "10px",
                borderRadius: "5px",
                cursor: "crosshair",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
            className="h-100"
        >
            <div
                style={{
                    position: "relative",
                    display: "inline-block",
                    width: "100%",
                    aspectRatio: "16/9",
                }}
                onClick={handleContentClick}
            >
                <img
                    ref={imageRef}
                    src={imageUrl}
                    alt="file"
                    style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                    }}
                    className="img-fluid rounded-4"
                    onLoad={updateSize}
                />

                {comments?.map((comment) => {
                    const scaleX = size.width / comment.screenWidth;
                    const scaleY = size.height / comment.screenHeight;
                    const adjustedX = comment.XLine * scaleX;
                    const adjustedY = comment.YLine * scaleY;
                    return (
                        <div
                            key={comment.id}
                            style={{
                                position: "absolute",
                                left: `${adjustedX}px`,
                                top: `${adjustedY}px`,
                                transform: "translate(-50%, -50%)",
                                cursor: "pointer",
                                zIndex: 999,
                            }}
                        >
                            <i
                                className={`${css.messageIcon} fa-solid fa-comment ${visibleCommentId === comment.id ? "activeColor" : "text-secondary"}`}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setActiveComment(comment);
                                    setVisibleCommentId(comment.id);
                                }}
                            ></i>
                        </div>
                    );
                })}
            </div>
            {showInput && (
                <div
                    className="form-control"
                    style={{
                        position: 'fixed',
                        bottom: '20px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        padding: '10px',
                        border: '1px solid #ccc',
                        zIndex: 1000,
                        width: '320px',
                        boxShadow: '0 2px 5px rgba(0,0,0,0.3)',
                    }}
                >
                    <textarea
                        value={currentCommentText}
                        onChange={(e) => setCurrentCommentText(e.target.value)}
                        placeholder="Enter your comment here..."
                        style={{
                            width: '100%',
                            height: '130px',
                            padding: '8px',
                            fontSize: '14px',
                            resize: 'none',
                        }}
                    />
                    <div className="d-flex gap-2 mt-1">
                        <button
                            className="btn btn-primary flex-grow-1"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleSaveComment(currentCommentText);
                                setCurrentCommentText('');
                            }}
                        >
                            Save
                        </button>
                        <button
                            className="btn btn-outline-danger"
                            onClick={(e) => {
                                e.stopPropagation();
                                setShowInput(false);
                                setCurrentCommentText('');
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}
