import React, { useContext, useEffect, useState } from 'react';
import VendorsTable from './VendorsTable';
import { vendorLoginContext } from '../../Context/vendorLogin';

export default function Vendors() {
    const { decodeToken } = useContext(vendorLoginContext);
    const [pirmStatus, setPirmStatus] = "Pending";
    const Token = localStorage.getItem("Firebase Auth");
    const [companyName, setCompanyName] = useState('');
    const [userName, setuserName] = useState('');

    useEffect(() => {
        const decodedToken = decodeToken(Token);
        const {companyName} = decodeToken(localStorage.getItem("Token"));

        const info = decodedToken.name ? decodedToken?.name?.split(',') : [];
        const Name = info[0]?.trim();
        setCompanyName(companyName);
        setuserName(Name);
    }, [Token]);

    return (

        <div className="container-fluid ps-3 mt-3">
            <div className="row">
                <div className="col-md-4">
                    <h1 className="title">Vendor Portal</h1>
                </div>
                <p className='p-text '>Welcome <span>{userName}</span> to <span>{companyName}'s</span> Third-Party Portal. Here, you will be able to complete onboarding questionnaires as well as upload requested documents.</p>
                <p className='p-text fw-bold'>Open Assignments</p>
                {/* Show incomplete assignments as "Pending" */}
                <VendorsTable pirm_addon__Status__c={pirmStatus} setPirmStatus={setPirmStatus} />

                <p className='p-text fw-bold pt-5'>Completed Assignments</p>
                {/* Show completed assignments as "Completed" */}
                <VendorsTable pirm_addon__Status__c="Completed" />
            </div>
        </div>
    );
}
