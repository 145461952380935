import React from 'react';
import css from './style.module.css';

export default function TabPanel({ setTableTitel, status, tabName, activeTab, setActiveTab }) {

    return (
        <li
            className={`tabItem ${activeTab === status ? css.active : ''}`}
            onClick={() => { setActiveTab(status); setTableTitel(tabName); }}
        >
            <span>{tabName}</span>
        </li>
    );
}
