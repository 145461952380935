import React from "react";

const SavingOverlay = () => {
    return (
        <div 
            className="position-fixed w-100 h-100 top-0 start-0 bg-black d-flex justify-content-center align-items-center"
            style={{ zIndex: 9999, opacity: 0.8 }}
        >
            <div className="text-center">
                {/* Reload Icon */}
                <i className="fas fa-spinner fa-pulse text-white fa-3x"></i>
                <p className="text-white mt-4 fs-5">Saving Answer...</p>
            </div>
        </div>
    );
};

export default SavingOverlay;
