import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import { Security, LoginCallback } from "@okta/okta-react";
import LayOuts from "./components/LayOuts/LayOuts";
import Home from "./components/Home/Home";
import PolicesDetails from "./components/PolicesDetails/PolicesDetails";
import { useEffect } from "react";
import Protected from "./components/Protected/Protected";
import PoliciesProvider from "./Context/ploicesContext";

import { toRelativeUrl } from "@okta/okta-auth-js";
import Login from "./components/Login/Login";
import AppProvider from "./Context/AppContext";
import {
  OktaConfigProvider,
  useOktaConfig,
} from "./Context/CredentialsContext";
import Vendors from "./components/Vendors/Vendors";
import Questionnaires from "./components/Vendors/Questionnaires";
import VendorProvider from "./Context/vendorContext";
import { Helmet, HelmetProvider } from "react-helmet-async";
import SalsefoeceProvider from "./Context/SF.CredantialsContext";
import VendorLogin from "./components/Login/VendorLogin";
import VendorLoginProvider from "./Context/vendorLogin";
import { Decryption } from "./lib/Decryption";
import ForgetPassword from "./components/Login/ForgetPassword";
import Otp from "./components/Login/Otp";
import DomainSwitchProvider from "./Context/DomainSwitch.Context";
import PloicesLoginLoading from "./components/LoadingPages/PloicesLoginLoading";
import MarketingCompliance from "./components/Marketing Compliance/MarketingCompliance";
import MarketingDetails from "./components/Marketing Compliance/MarketingDetails";
import MarktingProvider from "./Context/MarktingContext";
import Setup from "./components/setup/Setup";
import SetupProvider from "./Context/SetupContext";

// import Questions from "./components/Vendors/Questions";

const CALLBACK_PATH = "/login/callback";

function App() {
  const navigate = useNavigate();
  const { oktaAuths, setOktaConfig } = useOktaConfig();
  const location = useLocation();

  useEffect(() => {
    const credentials = localStorage.getItem("_Id");

    if (credentials) {
      const decryptData = Decryption(credentials);
      setOktaConfig(decryptData);
    }
  }, [setOktaConfig]); // Ensure useEffect only depends on setOktaConfig

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin), {
      replace: true,
    });
  };
  if (
    location.pathname === "/vendorlogin" ||
    location.pathname === "/vendors" ||
    location.pathname === "/questionnaires" ||
    location.pathname === "/forgetpassword" ||
    location.pathname === "/otp"
  ) {
    return (
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Compliance Portal</title>
        </Helmet>
        <SalsefoeceProvider>
          <DomainSwitchProvider>
            <VendorLoginProvider>
              <VendorProvider>
                <Routes>
                  <Route element={<LayOuts />}>
                    <Route
                      path="/vendors"
                      element={
                        <Protected>
                          <Vendors />
                        </Protected>
                      }
                    />
                    <Route
                      path="/questionnaires"
                      element={
                        <Protected>
                          <Questionnaires />
                        </Protected>
                      }
                    />
                    <Route path="/vendorlogin" element={<VendorLogin />} />
                    <Route
                      path="/forgetpassword"
                      element={<ForgetPassword />}
                    />
                    <Route path="/otp" element={<Otp />} />
                  </Route>
                </Routes>
              </VendorProvider>
            </VendorLoginProvider>
          </DomainSwitchProvider>
        </SalsefoeceProvider>
      </HelmetProvider>
    );
  }

  return !oktaAuths ? (
    <SalsefoeceProvider>
      <DomainSwitchProvider>
        <PloicesLoginLoading />
      </DomainSwitchProvider>
    </SalsefoeceProvider>
  ) : (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Compliance Portal</title>
      </Helmet>
      <Security oktaAuth={oktaAuths} restoreOriginalUri={restoreOriginalUri}>
        <SalsefoeceProvider>
          <PoliciesProvider>
            <MarktingProvider>
              <AppProvider>
              <SetupProvider>
                <Routes>
                  <Route element={<LayOuts />}>
                    <Route path="/" element={<Login />} />
                    <Route
                      path="/home"
                      element={
                        <Protected>
                          <Home />
                        </Protected>
                      }
                    />
                    <Route path={CALLBACK_PATH} element={<LoginCallback />} />
                    <Route
                      path="/details/:name/:id"
                      element={
                        <Protected>
                          <PolicesDetails />
                        </Protected>
                      }
                    />
                    <Route
                      path="/marketingcompliance"
                      element={
                        <Protected>
                          <MarketingCompliance />
                        </Protected>
                      }
                    />
                    <Route
                      path="/marketing/details/:id"
                      element={
                        <Protected>
                          <MarketingDetails />
                        </Protected>
                      }
                    />
                    <Route
                      path="/setup"
                      element={
                        <Protected>
                          <Setup />
                        </Protected>
                      }
                    />
                    <Route path="*" element={<Navigate to="/" replace />} />
                  </Route>
                </Routes>
                </SetupProvider>
              </AppProvider>
            </MarktingProvider>
          </PoliciesProvider>
        </SalsefoeceProvider>
      </Security>
    </HelmetProvider>
  );
}

export default function Root() {
  return (
    <OktaConfigProvider>
      <App />
    </OktaConfigProvider>
  );
}
