import { memo, useEffect, useState } from "react";

// A separate, memoized component for displaying age
 const AgeDisplay = memo(({ createdAt }) => {
    const [age, setAge] = useState('');
  
    // Function to compute the age string based on the elapsed time.
    const computeAge = () => {
      const now = new Date();
      const created = new Date(createdAt);
      const diffMs = Math.max(0, now - created);
  
      if (diffMs < 86400000) { // less than 24 hours
        const hours = Math.floor(diffMs / (60 * 60 * 1000));
        const minutes = Math.floor((diffMs % (60 * 60 * 1000)) / (60 * 1000));
        return hours + "h " + minutes + "m";
      } else if (diffMs < 2592000000) { // less than 30 days
        const days = Math.floor(diffMs / 86400000);
        const remainingHours = Math.floor((diffMs % 86400000) / (3600000));
        return days + " day" + (days > 1 ? "s" : "") + " " + remainingHours + "h";
      } else if (diffMs < 31536000000) { // less than 365 days
        const months = Math.floor(diffMs / 2592000000);
        return months + " month" + (months > 1 ? "s" : "");
      } else {
        const years = Math.floor(diffMs / 31536000000);
        return years + " year" + (years > 1 ? "s" : "");
      }
    };
  
    useEffect(() => {
      // Compute age immediately on mount.
      setAge(computeAge());
  
      // Always update every minute.
      const timer = setInterval(() => {
        setAge(computeAge());
      }, 60000);
  
      return () => clearInterval(timer);
    }, [createdAt]);
  
    return <>{age}</>;
  });
  
  export default AgeDisplay;