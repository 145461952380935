import axios from "axios";
import { createContext, useEffect, useState, useContext } from "react";
import { SalesforceContext } from "./SF.CredantialsContext";

export const PoliciesContext = createContext([]);

export default function PoliciesProvider({ children }) {
  const { tokens, fetchCredentials } = useContext(SalesforceContext);
  const [animationTriggered, setAnimationTriggered] = useState(false);

  const [policies, setPolicies] = useState([]);
  useEffect(() => {
    if (tokens) {
      getPolicies(tokens);
    }
  }, [tokens]);

  const getPolicies = async (tokens) => {
    
    try {
      const { data } = await axios.post(
        "https://api-skhwrpqgyq-uc.a.run.app/polices",
        {}, // Empty body if no data is required
        {
          headers: { Authorization: `Bearer ${tokens}` }, // Authorization header
        }
      );
      setPolicies(data);
    } catch (error) {
      if (
        error.response?.status === 401 &&
        error.response?.data?.message === "Token expired"
      ) {
        try {
          console.log("Token expired. Refreshing token...");
          const refreshedToken = await fetchCredentials(); // Refresh the token
          if (refreshedToken) {
            console.log("Retrying getPolicies with refreshed token...");
            await getPolicies(); // Retry the API call with the new token
          }
        } catch (refreshError) {
          console.error("Error refreshing token:", refreshError);
          // Optionally, provide user feedback about the failure
        }
      } else {
        const errorMessage =
          error.response?.data?.message || "Error fetching policies";
        console.error("Error fetching policies:", errorMessage);
      }
    }
  };

  return (
    <PoliciesContext.Provider value={{ policies, getPolicies,animationTriggered, setAnimationTriggered }}>
      {children}
    </PoliciesContext.Provider>
  );
}
