import React from "react";
import Select from "react-select";
import { useField } from "formik";

const SelectOption = ({ label, options, placeholder, ...props }) => {
  const [field, meta, helpers] = useField(props.name);
  const { setValue, setTouched } = helpers;

  const handleChange = (selectedOption) => {
    setValue(selectedOption ? selectedOption.value : "");
  };

  return (
    <div className="mb-3">
      <label htmlFor={props.name}>{label}</label>
      <Select
        id={props.name}
        name={props.name}
        options={options}
        onChange={handleChange}
        onBlur={() => setTouched(true)}
        // Set the value by finding the option that matches the Formik field value.
        value={options.find(option => option.value === field.value) || null}
        placeholder={placeholder}
        isSearchable
      />
      {meta.touched && meta.error ? (
        <div style={{ color: "red" }}>{meta.error}</div>
      ) : null}
    </div>
  );
};

export default SelectOption;
