import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

export default function Protected({ children }) {
    const location = useLocation();

    const compareUserId = (storedUserId) => {
        const token = localStorage.getItem("Firebase Auth");
        if (token) {
            try {
                const decodedToken = jwtDecode(String(token));

                // Check if the token has expired
                const currentTime = Math.floor(Date.now() / 1000);
                if (decodedToken.exp && decodedToken.exp < currentTime) {
                    console.warn("Token has expired, removing Vendor User.");
                    localStorage.removeItem("Vendor User");
                    localStorage.removeItem("Firebase Auth"); // Optional: Remove the token as well
                    return false;
                }

                if (decodedToken.user_id) {
                    localStorage.setItem("Vendor User", decodedToken.user_id);
                    return storedUserId === decodedToken.user_id;
                }
            } catch (error) {
                console.error("Error decoding token:", error);
                localStorage.removeItem("Vendor User"); // Remove if the token is invalid
                localStorage.removeItem("Firebase Auth");
                return false;
            }
        }
        return false;
    };

    if (
        location.pathname === "/vendors" || location.pathname === "/questionnaires" || location.pathname === "/forgetpassword" || location.pathname === "/otp"
    ) {
        localStorage.removeItem("sessionId");

        const userId = localStorage.getItem("Vendor User");
        return compareUserId(userId) ? children : <Navigate to="/vendorlogin" />;
    }
    const sessionId = localStorage.getItem("sessionId");
    return sessionId ? children : <Navigate to="/" />;
}
