import React, { useContext, useEffect, useState } from 'react';
import MarketingComplianceTable from './Table';
import { AnimatePresence, motion } from "framer-motion";
import { MarktingContext } from '../../Context/MarktingContext';
import AddMarktingForm from './AddMarktingForm';
import { useOktaConfig } from '../../Context/CredentialsContext';
import css from './style.module.css';
import TabPanel from './TabPanel';

export default function MarketingCompliance() {
  const { marktingData } = useContext(MarktingContext);
  const [markting, setMarkting] = useState(null);
  const [animationPlayed, setAnimationPlayed] = useState(false);
  const { loginToken } = useOktaConfig();
  const [activeTab, setActiveTab] = useState("Awaiting Review");
  const [tableTitel, setTableTitel] = useState("Awaiting Compliance Review");

  useEffect(() => {
    if (marktingData) {
      setMarkting(marktingData);
    }
  }, [marktingData]);

  if (!markting) {
    return <p>Loading...</p>;
  }

  return (
    <div className="container-fluid mt-3 ms-3">
      <div className="row align-items-center">
        <div className="col-md-12">
          <div className="d-flex align-items-end mb-2 justify-content-between">
            <h4 className="title ">Marketing Compliance</h4>
            <button
              className={`btn ${animationPlayed ? css.btnDanger : "btnCustom"} d-flex justify-content-center align-items-center rounded-5 me-2`}
              onClick={() => setAnimationPlayed(!animationPlayed)}
              style={{ width: "15%", maxWidth: "203px" }}
            >
              <AnimatePresence mode="wait">
                <motion.span
                  key={animationPlayed ? "cancel" : "add"}
                  initial={{ opacity: 0, y: 5 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -5 }}
                  transition={{ duration: 0.3, ease: "easeInOut" }}
                >
                  {animationPlayed ? "Cancel" : "Add New Document"}
                </motion.span>
              </AnimatePresence>
            </button>
          </div>
          {/* Marketing Form */}
          <AddMarktingForm loginToken={loginToken} animationPlayed={animationPlayed} />

          {/* Tabs */}
          <ul className="tabsContainer">
            <TabPanel
              setTableTitel={setTableTitel}
              status="Awaiting Review"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              tabName="Awaiting Compliance Review"
            />
            <TabPanel
              setTableTitel={setTableTitel}
              status="Comments Provided"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              tabName="Compliance Comments Provided"
            />
            <TabPanel
              setTableTitel={setTableTitel}
              status="Adjustments Made"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              tabName="Marketing Adjustments Made"
            />
            <TabPanel
              setTableTitel={setTableTitel}
              status="Active Version"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              tabName="Active Version - In Circulation"
            />
          </ul>

          {/* Table (moved outside of the ul) */}
          <MarketingComplianceTable data={markting} tableTitel={tableTitel} status={activeTab} />
        </div>
      </div>
    </div>
  );
}
