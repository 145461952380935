import { Outlet, useLocation } from "react-router-dom";
import NavBar from "../NavBar/NavBar";
import Footer from "../Footer/Footer";
import css from './style.module.css'; // Import the CSS module

export default function LayOuts() {
  const location = useLocation();

  // Paths where only the Outlet is displayed
  const footerOnlyPaths = ["/vendorlogin", "/otp", "/forgetpassword", "/", "/login/callback"];

  return (
    footerOnlyPaths.includes(location.pathname) ? (
      <div className={css.centeredLayout}>
        <div className={css.contentContainer}>
          <Outlet />
        </div>
        <Footer />
      </div>
    ) : (
      <section className="container-fluid">
        <div className="row p-0 m-0" style={{ minHeight: "100vh" }}>
          <div className="col-md-1">
            <NavBar />
          </div>
          <div className="col-md-11 d-flex flex-column">
            <div style={{ flex: 1 }}>
              <Outlet />
            </div>
            <Footer />
          </div>
        </div>
      </section>
    )
  );
}
