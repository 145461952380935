import { Link } from 'react-router-dom';

export default function MainDetails({ data }) {

  const sortedData = [...data].sort((a, b) => {
    const dateA = new Date(a.CreatedDate);
    const dateB = new Date(b.CreatedDate);
    return dateB - dateA;
  });

  return (
    <>
      <div className='row m-auto'>
        <p className='mb-4' style={{ fontWeight: "200", fontSize: "1.5rem" }}>Latest Documents</p>
        {sortedData.slice(0, 4).map((policy, index) => (
          <div
            key={index}
            id='Content'
            className="col-lg-3 col-md-6 col-sm-12 mb-4 px-4 mb-5"
          >
            <Link
              to={`/details/${policy.Name}/${policy.Id}`}
              state={{ policy }}
              className="text-decoration-none text-dark"
            >
              <div className='px-3 h-100 d-flex flex-column documents' style={{ border: '1px solid #D8D6D6', borderRadius: "25px" }}>
                <div className='d-flex mt-3'>
                  <i className="fa-solid fa-book mt-1 me-2"></i>
                  <div>
                    <p className={`policeName`}>{policy.Name}</p>
                    <p>Type: {policy.PIRM_Base__Record_Type__c}</p>
                    <p className=''>Version: {policy.PIRM_Base__Version__c ?? ""}</p>
                    <p className=''>Effective Date: {(() => {
                      if (policy.PIRM_Base__Effective_Date__c) {
                        const date = policy.PIRM_Base__Effective_Date__c.split("T")[0];
                        const [year, month, day] = date.split("-");
                        return `${month}/${day}/${year}`;
                      }
                      else {
                        return " ";
                      }
                    })()}</p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </>
  );
}
