import React, { useEffect } from 'react';
import css from './style.module.css';

export default function FileDetails({ createdBy, dataAssigned, documentStatus, firstVersion }) {
  useEffect(() => {
    console.log("### createdBy => ", createdBy);
    console.log("### documentStatus => ", documentStatus);
    console.log("### firstVersion => ", firstVersion);
  }, [createdBy, documentStatus, firstVersion]);

  return (
    <table className="table table-borderless ">
      <tbody className={css.bgTransparent}>
        <tr >
          <td >
            <span className={`fw-semibold ${css.keys}`}>Created by: </span>
            {createdBy || ""}
          </td>
          <td>
            <span className={`fw-semibold ${css.keys}`}>Date Assigned: </span>
            {dataAssigned ? dataAssigned.split("T")[0] : ""}
          </td>
          {firstVersion?.approved_by &&
            (firstVersion.approved_by !== "" || firstVersion.approved_by !== null) && (
              <td>
                <span className={`fw-semibold ${css.keys}`}>Reviewed by: </span>
                {createdBy || ""}
              </td>
            )}
        </tr>
        <tr>
          {firstVersion && firstVersion.documentType && firstVersion.documentType !== "" && (
            <td>
              <span className={`fw-semibold ${css.keys}`}>Document Type: </span>
              {firstVersion.documentType}
            </td>
          )}
          {firstVersion && firstVersion.mediaChannel && firstVersion.mediaChannel !== "" && (
            <td>
              <span className={`fw-semibold ${css.keys}`}>Media Channel: </span>
              {firstVersion.mediaChannel}
            </td>
          )}
          {firstVersion && firstVersion.targetGeography && firstVersion.targetGeography !== "" && (
            <td>
              <span className={`fw-semibold ${css.keys}`}>Target Geography: </span>
              {firstVersion.targetGeography}
            </td>
          )}
        </tr>
        {firstVersion && firstVersion.description && firstVersion.description !== "" && (
          <tr>
            <td colSpan="3">
              <span className={`fw-semibold ${css.keys}`}>Description: </span>
              {firstVersion.description}
            </td>
          </tr>
        )}
      </tbody>
    </table>


  );
}
