import axios from "axios";
import { createContext, useEffect, useState } from "react";
import { useOktaConfig } from "./CredentialsContext";
import { useNavigate } from "react-router-dom";

export const MarktingContext = createContext(null);


export default function MarktingProvider({ children }) {
  const { loginToken } = useOktaConfig();

  const [marktingData, setMarktingData] = useState([]);
  const [userName, setUserName] = useState('');
  const navigation = useNavigate();

 

  // marketing data
  const fetchMarktingData = async () => {
    try {
      const { data } = await axios.get("https://us-central1-policy-portal-4ca94.cloudfunctions.net/credentialsFunc/marketing",
        {
          headers: {
            Authorization: `Bearer ${loginToken}`,
          },
        }
      );

      setMarktingData(data.data);
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.removeItem("Firebase Auth");
        localStorage.removeItem("okta-token-storage");
        localStorage.removeItem("okta-cache-storage");
        navigation('/');
        console.error("seassion expired");
        return;
      };
      console.error("Error fetching data:", error);
    }
  };

  // The empty array ensures the effect runs only once after the component mounts
  useEffect(() => {
    if (loginToken) {
      fetchMarktingData();
    }
  }, [loginToken]); // Empty dependency array ensures this runs once

  // public url (expire in 1 hour)
  async function fetchPublicUrl(id) {
    try {
      const { data } = await axios.get(
        `https://us-central1-policy-portal-4ca94.cloudfunctions.net/credentialsFunc/content/url/${id}`,
        {
          headers: {
            Authorization: `Bearer ${loginToken}`,
          },
        }
      );
      return data.fileContent;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.removeItem("Firebase Auth");
        localStorage.removeItem("okta-token-storage");
        localStorage.removeItem("okta-cache-storage");
        navigation('/');
        console.error("seassion expired");
        return;
      };
      console.error('Error fetching content:', error);
      return null;
    }
  }
  return (
    <MarktingContext.Provider value={{ marktingData, setMarktingData, userName, setUserName, fetchPublicUrl }}>
      {children}
    </MarktingContext.Provider>
  );
}
