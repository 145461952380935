import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ProgressIndicator from './ProgressIndicator';
import css from './style.module.css';
import AddMarktingForm from './AddMarktingForm';
import DocumentComments from './DocumentComments';
import Header from './Header';

import { MarktingContext } from '../../Context/MarktingContext';
import LoadingMarketingDetails from '../LoadingPages/LoadingMarketingDetails';
import HTMLViewer from './HTMLViewer';

import ImageComponent from './ImageComponent';
import ConvertedHtml from './ConvertedHtml';
import { useOktaConfig } from '../../Context/CredentialsContext';
import FileDetails from './FileDetails';

export default function MarketingDetails() {
    const { fetchPublicUrl } = useContext(MarktingContext);
    const { loginToken } = useOktaConfig();


    const location = useLocation();
    const { data, status, firstVersion } = location.state || {};
    const [comments, setComments] = useState([]);
    // const [currentComment, setCurrentComment] = useState(null);
    // const [annotations, setAnnotations] = useState([]);
    const [id, setId] = useState(null);
    const [docInfo, setDocInfo] = useState({ documentUrl: [] });
    const [animationPlayed, setAnimationPlayed] = useState(false);
    const [currentVersion, setCurrentVersion] = useState(null);
    //   the active comment (ref. to the currently )
    const [activeComment, setActiveComment] = useState(null);
    // progress indicator file status
    const [filestatus, setFileStatus] = useState(null);
    const [convertedHtml, setConvertedHtml] = useState(false);
    const [documentStatus, setDocumentStatus] = useState(null);
    const documentUrl = typeof currentVersion === "string" ? currentVersion : "";
    const isImage = /\.(jpeg|jpg|gif|png|bmp)/i.test(documentUrl);
    const isHtml = [".html", ".htm", "HTML"].some(ext => documentUrl.includes(ext));



    useEffect(() => {
        async function fetchData() {
            if (data) {

                setDocInfo(firstVersion);
                setId(data?.documentId || data?.versionId);
                setConvertedHtml(data?.convertedBefore);
                const documentUrl = await fetchPublicUrl(data?.documentId || data?.versionId);
                setCurrentVersion(documentUrl);
                if (status) {
                    setDocumentStatus(status)
                }
            }
        }
        fetchData();
    }, [data]);

    if (!currentVersion) {
        return <LoadingMarketingDetails />;
    }

    return (
        <div className="container-fluid mt-3 ms-3">
            <Header loginToken={loginToken} setId={setId} id={id} documentStatus={documentStatus ?? documentStatus} setDocumentStatus={setDocumentStatus} setDocInfo={setDocInfo} animationPlayed={animationPlayed} setAnimationPlayed={setAnimationPlayed} setCurrentVersion={setCurrentVersion} setConvertedHtml={setConvertedHtml} filestatus={filestatus} setFileStatus={setFileStatus} setComments={setComments} />
            <AddMarktingForm loginToken={loginToken} setDocInfo={setDocInfo} animationPlayed={animationPlayed} marketingDocId={docInfo?.documentId} />
            <FileDetails createdBy={data?.created_by} dataAssigned={data?.createdAt} documentStatus={documentStatus ?? documentStatus} firstVersion={docInfo} />
            <ProgressIndicator documentStatus={documentStatus ?? documentStatus} setDocumentStatus={setDocumentStatus} filestatus={filestatus} setFileStatus={setFileStatus} />
            <div className="row d-flex">
                <div className="col-md-9 vh-100">
                    {isImage ? (
                        currentVersion && (
                            <>
                                <ImageComponent loginToken={loginToken} imgUrl={currentVersion} setActiveComment={setActiveComment} setComments={setComments} comments={comments} id={id} />
                            </>
                        )
                    ) :
                        isHtml ? (
                            !convertedHtml ? <HTMLViewer loginToken={loginToken} setActiveComment={setActiveComment} setComments={setComments} comments={comments} id={id} /> :
                                <ConvertedHtml loginToken={loginToken} id={id} comments={comments} setComments={setComments} setActiveComment={setActiveComment} />
                        ) :
                            (
                                <iframe
                                    src={currentVersion ?? currentVersion}
                                    width="100%"
                                    height="100%"
                                    title="File Viewer"
                                    className="rounded-4 px-1 py-2"
                                />
                            )}
                </div>
                <div className="col-md-3">
                    <DocumentComments loginToken={loginToken} setComments={setComments} comments={comments} id={id} activeComment={activeComment} />
                </div>
            </div>
        </div>
    );
}
