import React from 'react';
import css from './style.module.css';

const STEP_ORDER = ['Awaiting Review', 'Comments Provided', 'Adjustments Made', 'Active Version'];

export default function ProgressIndicator({ documentStatus, filestatus, setFileStatus }) {
  
  const currentStepIndex = STEP_ORDER.indexOf(documentStatus);

  return (
    <div className={`${css.indicatorContainer} my-3`}>
      <div className={css.row}>
        {STEP_ORDER.map((stepStatus, index) => {
          const isActive = filestatus === stepStatus;
          const isCompleted = currentStepIndex >= index;
          const showBodyArrow = isCompleted;
          const textColor = isActive || isCompleted ? 'text-white' : 'text-dark';

          return (
            <div key={stepStatus} className={css.colMd4}>
              <div
                onClick={() => setFileStatus(stepStatus)}
                className={`${css.indicator} 
                  ${showBodyArrow ? css.bodyArrow : ''} 
                  ${isActive ? css.indicatorActive : ''} 
                  d-flex align-items-center justify-content-center`}
              >
                <p className={`text-center m-0 fw-semibold ${textColor}`}>
                  {stepStatus}
                </p>
                <div className={css.arrowRightStart}></div>
                <div className={`${css.arrowRight} 
                  ${showBodyArrow ? css.borderArrow : ''} 
                  ${isActive ? css.arrowRightActive : ''}`}>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}